import React, { forwardRef, useContext } from 'react'
import { Button } from './CatalogHeader.styles'
import Dropdown from 'react-bootstrap/Dropdown'
import AuthContext from '@contexts/Auth'
import Logo from '@resources/imgs/logo-proseed-2-4.png'
import '@components/Header/header.css'
import { FaHome } from 'react-icons/fa'
import { Username } from '@components/Header/Header.styles'
import Avatar from '@resources/imgs/mask-woman.jpg'
import { CiLogout } from 'react-icons/ci'

const Item = forwardRef(({ children, onClick, }: any, ref: any) => (
  <Button
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >{children}</Button>
))

Item.displayName = 'Item'

const CatalogHeader: React.FC<any> = () => {
  const { user, isLoading, logout } = useContext(AuthContext)

  return (
    <header className="pt-4 pb-4 bg-white">
      <div className="container">
        <div className="row justify-content-between align-items-center pl-4 pr-4">
          <div className="col-9 col-sm-9 col-md-3 text-center">
            <img src={Logo} alt="" className="img-fluid" />
          </div>
          <div className="d-flex col-3 col-md-9 col-sm-3 justify-content-center justify-content-md-end align-items-center">
            <a href="#0!" className="mr-0 mr-md-3 btn btn-outline-primary pl-2 pr-2 d-none d-md-block "><FaHome /> Início</a>
            <button className="btn btn-primary pl-3 pr-3 d-none">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.1356 19.6309C18.1163 19.6309 17.2871 20.4601 17.2871 21.4793C17.2871 22.4986 18.1163 23.3278 19.1356 23.3278C20.1549 23.3278 20.9841 22.4986 20.9841 21.4793C20.9841 20.4601 20.1549 19.6309 19.1356 19.6309Z" fill="white"/>
                <path d="M9.46567 19.6309C8.44638 19.6309 7.61719 20.4601 7.61719 21.4793C7.61719 22.4986 8.44638 23.3278 9.46567 23.3278C10.485 23.3278 11.3142 22.4986 11.3142 21.4793C11.3142 20.4601 10.485 19.6309 9.46567 19.6309Z" fill="white"/>
                <path d="M14.6448 1.31641C14.2887 1.31641 14 1.60506 14 1.96123V9.98217L20.4863 3.49603C18.9191 2.13872 16.8762 1.31641 14.6448 1.31641Z" fill="white"/>
                <path d="M23.574 9.98287C23.5117 7.8558 22.7018 5.91329 21.3989 4.4082L15.8242 9.98287H23.574Z" fill="white"/>
                <path d="M14.6455 19.1839C19.2254 19.1839 23.0105 15.7193 23.5195 11.2734H5.77148C6.28012 15.7192 10.0652 19.1839 14.6455 19.1839Z" fill="white"/>
                <path d="M2.21284 5.46094C1.85668 5.46094 1.56836 5.74976 1.56836 6.10576C1.56836 6.46192 1.85735 6.75058 2.21351 6.75058C4.05914 6.75058 5.56506 8.17506 5.70108 9.98273H6.99424C6.85739 7.47029 4.7802 5.46094 2.21284 5.46094Z" fill="white"/>
              </svg>
            </button>

            {!isLoading && user ? (
              <ul className="navbar-nav navbar-right d-flex align-items-center">
                <li className="dropdown">
                  <Dropdown>
                    <Dropdown.Toggle as={Item} variant="primary">
                      <img alt="image" src={Avatar} style={{ maxWidth: 40 }} className="rounded-circle mx-2"/>
                      <Username className="d-none d-md-block text-end">
                        {user?.name}
                      </Username>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <a href="#" className="dropdown-item has-icon text-danger" onClick={logout}>
                        <CiLogout /> Sair
                      </a>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            ) : null}
          </div>
        </div>

      </div>
    </header>
  )
}

export default CatalogHeader
