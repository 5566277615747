import '@resources/fontes/fontes.css'
import React from 'react'
import Santander from '@resources/imgs/bank.png'
import Brasil from '@resources/imgs/banco-do-brasil-logo-0.png'

const Ted: React.FC<any> = () => {

  return (
    <>
      <div className="line-content ted">
        <div className="check-sample">
          <div className="text mb-3">
            <h5>Principais contas para realizar o TED:</h5>
          </div>
          <div className="left d-flex">
            <div className="bank mr-5">
              <div className="title">
                <figure>
                  <img className="img-fluid" src={Brasil} alt="" />
                </figure>
                Banco do Brasil
              </div>
              <div className="text">
                <p>AG: 53698521-12</p>
                <p>C/c: 6165468412-3</p>
                <p>CNPJ: 22.312.316/0001-22</p>
              </div>
            </div>
            <div className="bank">
              <div className="title">
                <figure>
                  <img className="img-fluid" src={Santander} alt="" />
                </figure>
                Santander
              </div>
              <div className="text">
                <p>AG: 53698521-12</p>
                <p>C/c: 6165468412-3</p>
                <p>CNPJ: 22.312.316/0001-22</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Ted
