import React, { useState, useEffect, forwardRef, useRef, useCallback } from 'react'
import { Card } from 'react-bootstrap'
import { useDropzone, FileWithPath, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import { FaClinicMedical } from 'react-icons/fa'
import iicon from '@resources/imgs/i-icon.svg'
import Esperm from '@resources/imgs/esperm.svg'
import Tratamento from '@resources/imgs/esperma-1.svg'
import Hospital from '@resources/imgs/hospital.svg'
import Edit from '@resources/imgs/edit.svg'
import Esperma1 from '@resources/imgs/esperma--1.svg'
import Medico from '@resources/imgs/medico--1.svg'
import Add from '@resources/imgs/useradd.svg'
import { IoIosCheckmark } from 'react-icons/io'
import { IoIosMove } from 'react-icons/io'
import { IoTrash } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import '@resources/css/request.css'
import '@resources/css/patient.css'
import '@resources/css/donator.css'
import '@resources/css/donators.css'
import 'swiper/css/navigation'
import { Step, Stepper } from 'react-form-stepper'
import LogoProssed from '@resources/imgs/logo-proseed-2.png'
import Photo from '@resources/imgs/mask-group-2.png'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import SummaryModal from '@components/Modals/SummaryModal'
import CardSample from '@components/Cards/CardSample/CardSample'
import { FiAlertTriangle } from 'react-icons/fi'

const animatedComponents = makeAnimated()

function handleResize() {
  const elements = document.querySelectorAll('.dinamica')
  elements.forEach(element => {
    if (window.innerWidth === 430) {
      element.classList.add('col-6')
    } else {
      element.classList.remove('col-6')
    }
  })
}

window.addEventListener('resize', handleResize)
handleResize()

const options = [
  { value: 'A7', label: 'A7' }
]

const Request: React.FC<any> = () => {
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const [dropzoneVisible, setDropzoneVisible] = useState<boolean>(false)
  // DND consts
  const [selectedDonors, setSelectedDonors] = useState([
    { id: '1', treatment: 'FIV' },
    { id: '2', treatment: 'IIU' },
    { id: '3', treatment: 'FIV' },
    { id: '4', treatment: 'FIV' },
    { id: '5', treatment: 'FIV' },
  ])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    multiple: true,
    onDrop: (acceptedFiles: FileWithPath[]) => handleDrop(acceptedFiles),
  })

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    const newImages = acceptedFiles.map((file) => URL.createObjectURL(file)).slice(0, 3)
    const totalImages = selectedImages.length + newImages.length

    if (totalImages <= 3) {
      setSelectedImages([...selectedImages, ...newImages])
    } else {
      console.log()
    }
  }

  const [isChecked, setChecked] = useState(false)
  const [step, setStep] = useState(0)
  const [numDoadores, setNumDoadores] = useState(0)
  const [exibirAlertaSuccess, setExibirAlertaSuccess] = useState(false)
  const [selectedTreatments, setSelectedTreatments] = useState(['FIV'])
  const handleRemoveCard = (index: number) => {
    setNumDoadores((prevNumDoadores) => Math.max(0, prevNumDoadores - 2))
  }

  useEffect(() => {
    setExibirAlertaSuccess(numDoadores >= 3)
  }, [numDoadores])

  const handleCheckChange = () => {
    setChecked(!isChecked)
  }

  const onClick = (event: any) => {
    console.log(event)
  }

  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleRemoveDonator = (index: number) => {
    setNumDoadores((prevNumDoadores) => Math.max(0, prevNumDoadores - 2))
  }

  const handleRemoveDonatorClick = (index: number) => () => {
    handleRemoveDonator(index)
  }

  const handleAddDoadores = () => {
    setNumDoadores((prevNumDoadores: number) => prevNumDoadores + 2)
  }

  const handleRemoveCardClick = (index: number) => () => {
    handleRemoveCard(index)
  }

  const handleCheckboxChange = () => {
    setDropzoneVisible(!dropzoneVisible)
  }

  const [cupomValid, setCupomValid] = useState(false)

  const handleCupomVisible = () => {
    setCupomValid(!cupomValid)
  }

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)

    setNumDoadores((prevNumDoadores) => Math.max(0, prevNumDoadores - 2))
  }

  const [showSummaryModal, setShowSummaryModal] = useState(false)

  const handleShowSummaryModal = () => {
    setShowSummaryModal(true)
  }

  const handleCloseSummaryModal = () => {
    setShowSummaryModal(false)
  }

  const alertaPrimaryClass = numDoadores >= 4 ? 'd-none' : 'd-block'
  const alertaSuccessClass = exibirAlertaSuccess ? 'd-block' : 'd-none'

  const handleMoveUp = (id: string) => {
    setSelectedDonors(prevDonors => {
      const index = prevDonors.findIndex(donor => donor.id === id)
      if (index <= 0) return prevDonors

      const newDonors = [...prevDonors]
      const temp = newDonors[index]
      newDonors[index] = newDonors[index - 1]
      newDonors[index - 1] = temp

      return newDonors
    })
  }

  const handleMoveDown = (id: string) => {
    setSelectedDonors(prevDonors => {
      const index = prevDonors.findIndex(donor => donor.id === id)
      if (index === -1 || index === prevDonors.length - 1) return prevDonors

      const newDonors = [...prevDonors]
      const temp = newDonors[index]
      newDonors[index] = newDonors[index + 1]
      newDonors[index + 1] = temp

      return newDonors
    })
  }

  return (
    <section className="steps-area">
      <>
        <div className="container">
          <Stepper activeStep={step} className="mb-3 align-items-stretch">
            <Step label="Qual é clínica a de tratamento?" completed={step > 0} onClick={() => setStep(0)} active={step === 0} />
            <Step label="Validação de Doadores" completed={step > 1} onClick={() => setStep(1)} active={step === 1} />
            <Step label="Check-out" completed={step > 2} onClick={() => setStep(2)} active={step === 2} />
          </Stepper>
        </div>

        <div className="container">
          {step === 0 && (
            <>
              <div className="request-page">
                <div className="title mb-3">
                  <h5>Criação de uma nova solicitação</h5>
                  <p>Preencha os dados da clínica, médico responsável e seus dados para o tratamento, assim agilizaremos o atendimento.</p>
                </div>
                <div className="select-sample">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="sample-box mt-2">
                        <div className="text d-flex">
                          <FaClinicMedical />
                          <p>Dados da clínica</p>
                          <img className="iicon" src={iicon} alt="" />
                        </div>
                        <div className="form-line">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Estado <span>*</span></p>
                                </div>
                                <Select
                                  className="react-select"
                                  placeholder={<div>UF</div>}
                                  components={animatedComponents}
                                  options={options}
                                  isClearable={true}
                                  isSearchable={true}
                                  isDisabled={false}
                                  isLoading={false}
                                  isRtl={false}
                                  closeMenuOnSelect={true}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-8">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Cidade <span>*</span></p>
                                </div>
                                <Select
                                  className="react-select"
                                  placeholder={<div>Selecione</div>}
                                  components={animatedComponents}
                                  options={options}
                                  isClearable={true}
                                  isSearchable={true}
                                  isDisabled={false}
                                  isLoading={false}
                                  isRtl={false}
                                  closeMenuOnSelect={true}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-line">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Clínica de tratamento <span>*</span></p>
                                </div>
                                <Select
                                  className="react-select"
                                  placeholder={<div>Selecione a Clínica</div>}
                                  components={animatedComponents}
                                  options={options}
                                  isClearable={true}
                                  isSearchable={true}
                                  isDisabled={false}
                                  isLoading={false}
                                  isRtl={false}
                                  closeMenuOnSelect={true}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-line form-switch d-flex">
                          <div className="custom-control custom-switch">
                            <input
                              className="custom-control-input d-flex"
                              type="checkbox"
                              role="switch"
                              onChange={handleCheckChange}
                              checked={isChecked}
                              id="has_own_lab"
                            />
                            <label className="custom-control-label" htmlFor="has_own_lab"></label>
                          </div>
                          <p className="mb-0">Minha clínica não está na seleção.</p>
                        </div>
                        <div className={`form-line ${isChecked ? '' : 'd-none'}`}>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Nome <span>*</span></p>
                                </div>
                                <input className="form-control" name="clinic-name" type="text" />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Contato <span>*</span></p>
                                </div>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Telefone <span>*</span></p>
                                </div>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Email <span>*</span></p>
                                </div>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="sample-box mt-2">
                        <div className="text d-flex">
                          <img src={Medico} alt="" />
                          <p>Dados da clínica</p>
                        </div>
                        <div className="form-line">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Médico responsável <span>*</span></p>
                                </div>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">Email <span>*</span></p>
                                </div>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box mb-2">
                                  <p className="mb-0">CRM <span>*</span></p>
                                </div>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="sample-box mt-2">
                        <div className="text d-flex">
                          <img src={Esperma1} alt="" />
                          <p>Dados tratamento</p>
                        </div>
                        <div className="form-line">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box d-flex mb-2">
                                  <p className="mb-0 mr-2">Tipo de tratamento <span>*</span></p>
                                  <img src={iicon} alt="" />
                                </div>
                                <Select
                                  className="react-select"
                                  placeholder={<div>Selecione</div>}
                                  components={animatedComponents}
                                  options={options}
                                  isClearable={true}
                                  isSearchable={true}
                                  isDisabled={false}
                                  isLoading={false}
                                  isRtl={false}
                                  closeMenuOnSelect={true}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-lg-6">
                              <div className="form-group">
                                <div className="text-box d-flex mb-2">
                                  <p className="mb-0 mr-2">N⁰ Amostras <span>*</span></p>
                                  <img src={iicon} alt="" />
                                </div>
                                <Select
                                  className="react-select"
                                  placeholder={<div>Selecione</div>}
                                  components={animatedComponents}
                                  options={options}
                                  isClearable={true}
                                  isSearchable={true}
                                  isDisabled={false}
                                  isLoading={false}
                                  isRtl={false}
                                  closeMenuOnSelect={true}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-line">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="text-box d-flex mb-2">
                                  <p className="mb-0">Data de tratamento <span>*</span></p>
                                </div>
                                <input className="form-control" type="date" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="request-footer">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                      <Link className="return-btn cancel-btn btn btn-primary" to="/paciente">
                        Cancelar
                      </Link>
                      <button className="next-btn btn btn-primary" onClick={() => setStep(1)}>
                        Próximo passo
                        <MdKeyboardArrowRight />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {step === 1 && (
            <>
              <div className="select-donor">
                <div className="title-tratament">
                  <div className="title">
                    <h5>Selecione os doadores e a quantidade de amostras</h5>
                    <p>Trouxemos as suas escolhas do seu carrinho. Será necessário no mínimo a escolha de dois doadores e no máximo de cinco, fique a vontade para trocar.</p>
                  </div>
                  <div className="tratament">
                    <img src={Tratamento} alt="" />
                    <div className="text">
                      <span>FIV</span>
                      <p>Tratamento</p>
                    </div>
                  </div>
                </div>
                <div className={`alert alert-danger mt-3 ${selectedDonors.filter((item) => selectedTreatments.includes(item.treatment)) ? 'd-block' : 'd-none'}`}>
                  <div className="d-flex align-items-center">
                    <FiAlertTriangle />
                    <p className="mb-0 ml-3">Será necessário remover os doadores marcados em vermelho abaixo pois não são compativeis com o seu tratamento escolhido!</p>
                  </div>
                </div>

                <div className="donors mySwiper box-card">
                  <div className="row align-items-stretch">
                    {selectedDonors.map((donator) => (
                      <CardSample
                        key={donator}
                        canSelect={false}
                        canDrag={true}
                        item={donator}
                        selectedTreatments={selectedTreatments}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                      />
                    ))}
                    <div className="col-12 col-lg-4 p-3">
                      <Link to="/" className="box" onClick={handleAddDoadores}>
                        <img src={Add} alt="" />
                        <p>Selecione um doador</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="request-footer">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                      <button className="return-btn btn btn-primary" onClick={() => setStep(0)}>
                        <MdKeyboardArrowLeft />
                        Retornar
                      </button>
                      <button className="register-btn btn btn-primary" onClick={() => setStep(2)}>
                        Próximo passo
                        <MdKeyboardArrowRight />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </>
          )}

          {step === 2 && (
            <>
              <div className="request-summer-page">
                <div className="row">
                  <div className="col-md-12 line title">
                    <h5>Revise sua solicitação</h5>
                    <p>Confira se todos os dados estão corretos para que possamos seguir com a solicitação.</p>
                  </div>
                  <div className="col-md-12">
                    <div className="check-sample">
                      <div className="check check-patient">
                        <div className="title pb-3 d-flex align-items-center">
                          <h6>Revise os dados do paciente:</h6>
                          <Link className="btn-edit w-50 d-flex ml-2" to="/perfil">
                            <img src={Edit} alt="" />
                            <span className="ml-2">Editar dados</span>
                          </Link>
                        </div>
                        <div className="summary">
                          <div className="line row align-items-center">
                            <div className="col-12 col-sm-12 col-lg-6">
                              <div className="box box-user mt-2">
                                <div className="name">
                                  <h6>Patricia Fernandez</h6>
                                  <span>Nome da solicitante</span>
                                </div>
                                <div className="cpf">
                                  <h6>066.315.879-89</h6>
                                  <span>CPF</span>
                                </div>
                                <div className="tell">
                                  <h6>(41) 99693-8565</h6>
                                  <span>Celular</span>
                                </div>
                                <div className="email">
                                  <h6>Empresa@empresa.com.br</h6>
                                  <span>E-mail</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6">
                              <div className="box box-partner mt-2">
                                <div className="name">
                                  <h6>João Carlos Gonçalves</h6>
                                  <span>Companheiro(a)</span>
                                </div>
                                <div className="cpf">
                                  <h6>525.698.336-69</h6>
                                  <span>CPF</span>
                                </div>
                                <div className="tell">
                                  <h6>(41) 99789-9532</h6>
                                  <span>Celular</span>
                                </div>
                                <div className="email">
                                  <h6>empresa2@empresa.com.br</h6>
                                  <span>E-mail</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="check check-clinic">
                        <div className="title pb-3 d-flex align-items-center mt-4">
                          <h6>Revise os dados da clínica e do tratamento:</h6>
                          <Link className="btn-edit w-50 d-flex" to="#">
                            <img src={Edit} alt="" />
                            <span>Editar dados</span>
                          </Link>
                        </div>
                        <div className="line summary">
                          <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-lg-4">
                              <div className="box adress">
                                <div className="clinic d-flex">
                                  <img src={Hospital} alt="" />
                                  <div className="text">
                                    <h6>Huntington</h6>
                                    <span>Clínica de tratamento</span>
                                  </div>
                                </div>
                                <div className="andress">
                                  <h6>São Paulo - SP</h6>
                                  <span>Localidade</span>
                                </div>
                                <div className="email">
                                  <h6>Empresa@empresa.com.br</h6>
                                  <span>E-mail</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-4">
                              <div className="box medic mt-2">
                                <div className="medic d-flex">
                                  <img src={Medico} alt="" />
                                  <div className="text">
                                    <h6>Dr. Carlos Albuquer</h6>
                                    <span>Médico responsável</span>
                                  </div>
                                </div>
                                <div className="email">
                                  <h6>Empresa@empresa.com.br</h6>
                                  <span>E-mail</span>
                                </div>
                                <div className="crm">
                                  <h6>9591233</h6>
                                  <span>CRM</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-4">
                              <div className="box tratament mt-2">
                                <div className="tratament d-flex">
                                  <img src={Esperma1} alt="" />
                                  <div className="text">
                                    <h6>Fertilização in vitro (FIV)</h6>
                                    <span>Tipo de tratamento</span>
                                  </div>
                                </div>
                                <div className="form-group d-flex">
                                  <div className="text-box d-flex">
                                    <p className="mb-0">Quantidade de amostras</p>
                                    <img src={iicon} alt="" />
                                  </div>
                                  <Select
                                    className="react-select"
                                    placeholder={<div>Selecione</div>}
                                    components={animatedComponents}
                                    options={options}
                                    isClearable={true}
                                    isSearchable={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    isRtl={false}
                                    closeMenuOnSelect={true}
                                  />
                                </div>
                                <div className="data">
                                  <h6>15 de novembro 2024</h6>
                                  <span>Data de tratamento</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="line check check-donors box-card">
                      <div className="title d-flex align-items-center pb-1 mt-4">
                        <h6>Revise os dados da clínica e do tratamento:</h6>
                        <Link className="btn-edit w-50 d-flex" to="#">
                          <img src={Edit} alt="" />
                          <span>Editar dados</span>
                        </Link>
                      </div>
                      <p>A ordem da escolha do doador é crucial, pois a clínica compra as amostras nessa sequência. Caso a primeira opção não esteja disponível, tentam com a próximo.</p>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <div className="card-index mt-2">
                            <Card className="donor-card large-card">
                              <Card.Header className="card-header-donator" id="card-swiper">
                              </Card.Header>

                              <Card.Body className="card-body-donator d-flex align-items-center justify-content-around">
                                <div className="priority">
                                  Prioridade
                                  <span>01</span>
                                </div>
                                <div className="donor-info d-flex">
                                  <figure className="d-flex justify-content-center mb-0">
                                    <img className="photo" src={Photo} alt="" />
                                  </figure>
                                  <div className="infos ml-3">
                                    <div className="id">ID: 13</div>
                                    <div className="amostra">Amostra Nacional</div>
                                  </div>
                                </div>
                                <figure className="mb-0">
                                  <img className="logo" src={LogoProssed} alt="" />
                                </figure>
                                <div className="tratament d-flex">
                                  <img src={Tratamento} alt="" />
                                  <span>FIV</span>
                                </div>
                                <div className="price">
                                  R$ 2.689,00
                                </div>
                                <button className="icons trash-icon">
                                  <IoTrash />
                                </button>
                              </Card.Body>
                            </Card>
                            <Card className="donor-card large-card">
                              <Card.Header className="card-header-donator" id="card-swiper">
                              </Card.Header>

                              <Card.Body className="card-body-donator d-flex align-items-center justify-content-around">
                                <div className="priority">
                                  Prioridade
                                  <span>02</span>
                                </div>
                                <div className="donor-info d-flex">
                                  <figure className="d-flex justify-content-center mb-0">
                                    <img className="photo" src={Photo} alt="" />
                                  </figure>
                                  <div className="infos ml-3">
                                    <div className="id">ID: 13</div>
                                    <div className="amostra">Amostra Nacional</div>
                                  </div>
                                </div>
                                <figure className="mb-0">
                                  <img className="logo" src={LogoProssed} alt="" />
                                </figure>
                                <div className="tratament d-flex">
                                  <img src={Tratamento} alt="" />
                                  <span>FIV</span>
                                </div>
                                <div className="price">
                                  R$ 2.689,00
                                </div>
                                <button className="icons trash-icon">
                                  <IoTrash />
                                </button>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line services">
                  <div className="title pb-3">
                    <h6>Contrate serviços adicionais:</h6>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="service">
                        <div className="line service-box mb-2">
                          <input
                            className="checkbox"
                            type="checkbox"
                            onChange={handleCheckboxChange}
                          />
                          <div className="zone">
                            <div className="text">
                              <h6>Photo Match</h6>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                            </div>
                            <div className={`dropzone-area ${dropzoneVisible ? 'd-block' : 'd-none'}`}>
                              <div className="title pb-3">
                                <h6>Insira suas fotos para que possamos identificar o melhor doador.</h6>
                                <p>Texto explicativo</p>
                              </div>
                              <div className="row row-col">
                                <div {...getRootProps()} className="dropzone d-flex flex-column">
                                  <input {...getInputProps() as DropzoneInputProps} />
                                  <img src={Add} alt="" />
                                  <button className="button">Insira suas fotos aqui</button>
                                </div>
                                {selectedImages.map((image, index) => (
                                  <div key={index} className="single-image dinamica">
                                    <img src={image} alt={`Imagem ${index + 1}`} className="image" />
                                    <button
                                      className="remove-button icons trash-icon"
                                      onClick={() => handleRemoveImage(index)}
                                    >
                                      <IoTrash />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="service-box">
                          <input className="checkbox" name="aconselhamento-genetico" type="checkbox" />
                          <div className="zone">
                            <div className="text">
                              <h6>Aconselhamento Genético</h6>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line transport">
                  <div className="title pb-3 mt-4">
                    <h6>Custo de transporte para clinicas fora de São Paulo</h6>
                    <p>Os valores apresentados para transporte são apenas uma estimativa e podem sofrer alteração sem prévio aviso e serão validados na confirmação de sua reserva.</p>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="transport-payment service">
                        <div className="text">
                          <h6>Padonatornto é feito para transportadora</h6>
                          <div className="text-price d-flex justify-content-between">
                            <p>O custo adicional de transporte deve ser pago diretamente a transportadora e o comprovante de padonatornto deverá ser enviado separadamente na guia <span>Envio do comprovante padonatornto</span>.</p>
                            <div className="price">
                              R$ 689,00
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line comment">
                  <div className="title pb-3">
                    <h6>Observação para Pro-Seed.</h6>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-area">
                        <input className="form-control" type="textarea" placeholder="Alguma mensagem que gostaria de deixar para nós?" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line cupom">
                  <div className="title pb-3">
                    <h6>Cupom de desconto:</h6>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="cupom-area area p-4">
                        <div className="input-area form-group">
                          <p>Insira o cupom:</p>
                          <input className="form-control" type="text" />
                          <button className="btn btn-primary pl-3" onClick={handleCupomVisible} >
                            Validar
                          </button>
                        </div>
                        <div className={`cupom-area ${cupomValid ? 'd-block' : 'd-none'}`}>
                          <div className="cupom-result d-flex align-items-center">
                            <p>Desconto gerado de</p>
                            <span>R$ 689,00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 request-footer request-check-footer large-responsive d-sm-none d-lg-block">
                  <div className="d-flex align-items-center justify-content-between">
                    <button className="return-btn btn btn-primary" onClick={() => setStep(1)}>
                      <MdKeyboardArrowLeft />
                      Retornar
                    </button>
                    <div className="price-btn d-flex align-items-center">
                      <div className="text">
                        <p>Valor total da solicitação:</p>
                        <h5 className="d-flex"><span className={`cupom-area mb-0 mr-1 ${cupomValid ? 'd-block' : 'd-none'}`}>-R$ 200,00 </span>R$ 11.355,00</h5>
                      </div>
                      <button className="register-btn btn btn-primary" onClick={handleShowSummaryModal}>
                        Realizar Solicitação
                        <MdKeyboardArrowRight />
                        <SummaryModal showModal={showSummaryModal} handleCloseModal={handleCloseSummaryModal} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="request-footer request-check-footer d-sm-block d-lg-none">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="price-btn">
                      <div className="text text-center">
                        <p>Valor total da solicitação:</p>
                        <h5 className="d-flex"><span className={`cupom-area mb-0 mr-1 ${cupomValid ? 'd-block' : 'd-none'}`}>-R$ 200,00 </span>R$ 11.355,00</h5>
                      </div>
                      <div className="buttons d-flex">
                        <button className="register-btn btn btn-primary mr-3" onClick={handleShowSummaryModal}>
                          Realizar Solicitação
                          <MdKeyboardArrowRight />
                          <SummaryModal showModal={showSummaryModal} handleCloseModal={handleCloseSummaryModal} />
                        </button>
                        <button className="return-btn btn btn-primary" onClick={() => setStep(1)}>
                          <MdKeyboardArrowLeft />
                          Retornar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </section>
  )
}

export default Request
