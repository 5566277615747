import '@resources/fontes/fontes.css'
import '@resources/css/contact.css'
import React, { useState, useRef } from 'react'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import '@resources/css/homeuser.css'
import { IoIosPhonePortrait } from 'react-icons/io'
import { FaHeart } from 'react-icons/fa'
import { CiMail } from 'react-icons/ci'
import Image from '@resources/imgs/image44021.png'
import Image3 from '@resources/imgs/image44020-1.png'
import Image1 from '@resources/imgs/image44022.png'
import Image2 from '@resources/imgs/image44023.png'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { BsChevronRight } from 'react-icons/bs'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import { useNavigate } from 'react-router-dom'

const animatedComponents = makeAnimated()

const options = [
  { value: 'Setor 1', label: 'Setor 1' },
  { value: 'Setor 2', label: 'Setor 2' }
]

const Contact: React.FC<any> = () => {
  const navigate = useNavigate()

  return (
    <div className="contact">
      <Sidebar />
      <main className="with-fixed-nav">
        <div className="container">
          <div className="row align-items-center justify-content-between mt-3 mb-3">
            <div className="col-7 col-sm-7 col-md-3 d-none d-md-block">
              <div className="row m-0">
                <div className="col-auto pr-0">
                  <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                    Home
                  </a>
                </div>
                <div className="col-auto pl-1 pr-1">
                  <BsChevronRight size={10} />
                </div>
                <div className="col-auto p-0">
                  <a href="#" className="id d-flex align-items-center font-weight-bold">
                    Contato
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-7 col-md-3 pl-5">
              <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
            </div>
            <div className="col-5 col-sm-5 col-md-6">
              <Header />
            </div>
          </div>
          <div className="pt-2 pb-2 d-block d-md-none">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Home
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Solicitação de pedidos
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="contact-section my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-mb-6 col-lg-6">
                <div className="left">
                  <div className="title">
                    <h1>Precisando falar com a Proseed?</h1>
                    <p>Estamos sempre de portas abertas para nossos clientes. Entre em contato via telefone ou envie uma mensagem pelo formulário.</p>
                  </div>
                  <div className="contact-text my-5">
                    <div className="tel d-flex mb-2">
                      <figure>
                        <IoIosPhonePortrait />
                      </figure>
                      (11) 92001-6275
                    </div>
                    <div className="mail d-flex">
                      <figure>
                        <CiMail />
                      </figure>
                      contato@proseed.com.br
                    </div>
                  </div>
                  <div className="support">
                    <figure>
                      <img className="image-0" src={Image} alt="" />
                      <img className="image-1" src={Image1} alt="" />
                      <img className="image-2" src={Image2} alt="" />
                      <img className="image-3" src={Image3} alt="" />
                    </figure>
                    <p>Estamos aqui para te apoiar e facilitar a escolha!! <span><FaHeart /></span></p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-mb-6 col-lg-6">
                <div className="right">
                  <div className="box">
                    <div className="form-group">
                      <div className="text-box mb-2">
                        <p className="mb-0">Qual setor? <span>*</span></p>
                      </div>
                      <Select
                        className="react-select teste"
                        placeholder={<div>Setor da empresa</div>}
                        components={animatedComponents}
                        options={options}
                        isClearable={true}
                        isSearchable={true}
                        isDisabled={false}
                        isLoading={false}
                        isRtl={false}
                        closeMenuOnSelect={true}
                      />
                    </div>
                    <div className="form-group">
                      <div className="text-box mb-2">
                        <p className="mb-0">Informe o que seria? <span>*</span></p>
                      </div>
                      <input className="form-control" type="text" placeholder="Título para o assunto" />
                    </div>
                    <div className="form-group">
                      <div className="text-box mb-2">
                        <p className="mb-0">Informe o que seria? <span>*</span></p>
                      </div>
                      <input className="form-control textarea" type="textarea" placeholder="Qual sua mensagem?" />
                    </div>
                    <div className="btn-group">
                      <button className="btn btn-primary">Enviar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </div>
  )
}

export default Contact
