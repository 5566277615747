import React, { useContext, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import LoginContext from '@contexts/Login'
import { Link } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import AuthContext from '@contexts/Auth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import '@resources/css/securitycode.css'
import Autenticateimg from '@resources/imgs/autenticate-img.png'
import LoginSuccessModal from '@components/Modals/LoginSuccessModal'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { MdKeyboardArrowRight } from 'react-icons/md'

const SecurityCode: React.FC<any> = () => {
  const { setUser, setToken, setIsAuthenticated } = useContext(AuthContext)
  const { email, setEmail, password, setPassword, isLoading, setIsLoading, error, setError } = useContext(LoginContext)

  const navigate = useNavigate()

  const [ securityCode, setSecurityCode ] = useState<string[]>(['', '', '', ''])
  const [ isLoadingResend, setIsLoadingResend ] = useState(false)
  const inputRefs = useRef<HTMLInputElement[]>([])

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...securityCode]
    values[index] = event.target.value
    setSecurityCode(values)

    if (event.target.value.length === 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      event.preventDefault()

      setSecurityCode(securityCode => {
        const values = [...securityCode]
        values[index] = ''
        return values
      })

      inputRefs.current[index - 1].focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault()
    const paste = event.clipboardData.getData('text')

    let index = 0

    for (let i = 0; i < 4; i++) {
      inputRefs.current[i].value = paste[index]

      index++
      if (index === 4)
        break
    }

    setSecurityCode(inputRefs.current.map(input => input.value))
  }

  const resend = () => {
    setIsLoadingResend(true)

    $Auth.securityCode(email, password).catch(e => {
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.')
    }).finally(() => setIsLoadingResend(false))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.login(email, password, securityCode.join('')).then(({ data: { user, token } }) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setIsAuthenticated(true)
      navigate('/')
    }).catch(e => {
      setError(e.response?.data?.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.')
    }).finally(() => setIsLoading(false))
  }

  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleShowSuccessModal = () => {
    setShowSuccessModal(true)
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false)
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6 offset-md-3">
        <div className="card card-full">
          <div className="card-header d-flex flex-column align-items-center pt-3">
            <figure>
              <img src={Autenticateimg} alt="" />
            </figure>
          </div>
          <form onSubmit={onSubmit}>
            <div className="card-body d-flex flex-column align-items-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}
              <h3>
                Você recebeu um código de confirmação via e-mail
              </h3>
              <p>Enviamos o código para <span>patricina.fernandez2023@gmail.com</span></p>
              <div className="row">
                {securityCode.map((code, index) => (
                  <div className="col-3" key={index}>
                    <input
                      ref={input => {
                        if (input) inputRefs.current[index] = input
                      }}
                      type="text"
                      className="form-control"
                      value={code}
                      onChange={event => handleChange(index, event)}
                      onKeyDown={event => handleKeyDown(index, event)}
                      onPaste={handlePaste}
                      maxLength={1}
                    />
                  </div>
                ))}
              </div>
              <div className="link-codigo">
                <p>Não recebeu um código? <Link to=""><span>clique para reenviar</span></Link></p>
              </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-center pb-3">
              <div className="row">
                <div className="col-6 col-lg-6">
                  <Link className="return-btn btn btn-primary" to="/">
                    <MdKeyboardArrowLeft />
                    Cancelar
                  </Link>
                </div>
                <div className="col-6 col-lg-6">
                  <button className="register-btn btn btn-primary" onClick={handleShowSuccessModal}>
                    Verificar
                    <MdKeyboardArrowRight />
                    <LoginSuccessModal showModal={showSuccessModal} handleCloseModal={handleCloseSuccessModal} />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SecurityCode
