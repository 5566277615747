export default {
  translations: {
    fields: {
      left: 'Canhoto',
      right: 'Destro',
      masters: 'Mestrado',
      doctorate: 'Doutorado',
      mba: 'MBA',
      phd: 'Ph.D',
    },
  }
}
