import React, { useEffect } from 'react'
import '@resources/css/tabs/features.css'
import IDonor from '@interfaces/IDonor'
import pdf from '@resources/files/caracteristicas3.pdf'

type Props = {
  donor: IDonor
}

const Features: React.FC<Props> = ({ donor }) => {

  useEffect(() => {
    const featureItemsCount = document.querySelectorAll('.feature-item').length
    const otherParticularity = document.querySelector('.other-particularity') as HTMLElement | null

    if (featureItemsCount > 0 && otherParticularity) {
      const lastRowCount = featureItemsCount % 5 - 1

      if (lastRowCount >= 3) {
        otherParticularity.style.flexBasis = '100%'
      } else {
        otherParticularity.style.flexBasis = `calc(${(5 - lastRowCount) * 20}% - 10px)`
      }
    }
  }, [donor])

  return (
    <>
      <section className="donator-about">
        <div className="features">
          <div className="d-flex flex-wrap">
            {donor.race && (
              <div className="feature-item">
                <h6 className="text-capitalize">Raça</h6>
                <p>{donor.race?.name}</p>
              </div>
            )}
            {donor.skin_color && (
              <div className="feature-item">
                <h6 className="text-capitalize">Cor da pele</h6>
                <p>{donor.skin_color.name}</p>
              </div>
            )}
            {donor.exposed_to_sun_skin_color && (
              <div className="feature-item">
                <h6 className="text-capitalize">Cor da pele exposta ao sol</h6>
                <p>{donor.exposed_to_sun_skin_color.name}</p>
              </div>
            )}
            {donor.has_freckles && (
              <div className="feature-item">
                <h6 className="text-capitalize">Sardas</h6>
                <p>{donor.has_freckles}</p>
              </div>
            )}
            {donor.face_shape && (
              <div className="feature-item">
                <h6 className="text-capitalize">Formato do rosto</h6>
                <p>{donor.face_shape?.name}</p>
              </div>
            )}
            {donor.eye_color && (
              <div className="feature-item">
                <h6 className="text-capitalize">Cor dos olhos</h6>
                <p>{donor.eye_color?.name}</p>
              </div>
            )}
            {donor.eye_shape && (
              <div className="feature-item">
                <h6 className="text-capitalize">Formato dos olhos</h6>
                <p>{donor.eye_shape?.name}</p>
              </div>
            )}
            {donor.hair_color && (
              <div className="feature-item">
                <h6 className="text-capitalize">Cor do cabelo</h6>
                <p>{donor.hair_color.name}</p>
              </div>
            )}
            {donor.hair_type && (
              <div className="feature-item">
                <h6 className="text-capitalize">Tipo de cabelo</h6>
                <p>{donor.hair_type.name}</p>
              </div>
            )}
            {donor.is_bald != null ? (
              <div className="feature-item">
                <h6 className="text-capitalize">Calvice</h6>
                <p>{donor.is_bald ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.nose_shape && (
              <div className="feature-item">
                <h6 className="text-capitalize">Formato do nariz</h6>
                <p>{donor.nose_shape}</p>
              </div>
            )}
            {donor.lip && (
              <div className="feature-item">
                <h6 className="text-capitalize">Lábios</h6>
                <p>{donor.lip.name}</p>
              </div>
            )}
            {donor.teeth_type && (
              <div className="feature-item">
                <h6 className="text-capitalize">Dentes</h6>
                <p>{donor.teeth_type.name}</p>
              </div>
            )}
            {donor.eyebrow && (
              <div className="feature-item">
                <h6 className="text-capitalize">Sobrancelha</h6>
                <p>{donor.eyebrow.name}</p>
              </div>
            )}
            {donor.bone_structure && (
              <div className="feature-item">
                <h6 className="text-capitalize">Estrutura óssea</h6>
                <p>{donor.bone_structure.name}</p>
              </div>
            )}
            {donor.particularities?.length > 0 && (
              <div className="feature-item">
                <h6 className="text-capitalize">Particularidades</h6>
                <p>{donor.particularities.join(', ')}</p>
              </div>
            )}
            {donor.other_particularity && (
              <div className="feature-item other-particularity">
                <h6 className="text-capitalize">Particularidades Marcantes</h6>
                <p>{donor.other_particularity}</p>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="know-about-caracteristics">
        <div className="row align-items-center">
          <div className="col-12 col-md-8 text-center text-md-left">
            <h5 className="mb-0">Quer entender melhor as características do Doador?</h5>
          </div>
          <div className="col-12 col-sm-12 col-md-4 text-center text-md-right mt-2 mt-md-0">
            <a href={pdf} target="_blank" rel="noreferrer">
              <button className="btn btn-primary">Características</button>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
