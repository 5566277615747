import '@resources/fontes/fontes.css'
import '@resources/css/report.css'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import CardAbout from '@components/Cards/CardAbout/CardAbout'
import Gestacao from '@resources/imgs/image-gestacao.png'
import UserPhoto from '@resources/imgs/userphoto.png'
import Maximize from '@resources/imgs/maximize.svg'
import Pais from '@resources/imgs/clippathgroup.svg'
import Hospital from '@resources/imgs/hospital.svg'
import { FaRegCirclePlay } from 'react-icons/fa6'
import YouTube from 'react-youtube'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import React, { useState, useEffect, ChangeEvent } from 'react'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import { BsChevronRight } from 'react-icons/bs'

const animatedComponents = makeAnimated()

const options = [
  { value: 'Gestação em andamento', label: 'Gestação em andamento' },
  { value: 'Nascimento', label: 'Nascimento' }
]

const Report: React.FC<any> = () => {
  const [selectedOption, setSelectedOption] = useState<any>(null)

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
  }
  const navigate = useNavigate()

  return (
    <div className="faq">
      <Sidebar />
      <main className="with-fixed-nav">
        <div className="container">
          <div className="row align-items-center justify-content-between mt-3 mb-3">
            <div className="col-7 col-sm-7 col-md-3 d-none d-md-block">
              <div className="row m-0">
                <div className="col-auto pr-0">
                  <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                    Home
                  </a>
                </div>
                <div className="col-auto pl-1 pr-1">
                  <BsChevronRight size={10} />
                </div>
                <div className="col-auto p-0">
                  <a href="#" className="id d-flex align-items-center font-weight-bold">
                    Reporte sua gestação
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-7 col-md-3 pl-5">
              <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
            </div>
            <div className="col-5 col-sm-5 col-md-6">
              <Header />
            </div>
          </div>
          <div className="pt-2 pb-2 d-block d-md-none">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Home
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Solicitação de pedidos
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="faq-section mt-3">
          <div className="container">
            <div className="question">
              <div className="row">
                <div className="col-12 col-sm-12 col-mb-6 col-lg-6">
                  <div className="text">
                    <h2>Já fiz meu procedimento e agora?</h2>
                    <h5>Queremos saber um pouco mais sobre a sua gestação.</h5>
                    <h6>Ajude-nos a contabilizar e acompanhar o número de nascimento por doador.</h6>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-mb-6 col-lg-6">
                  <figure>
                    <img className="img-fluid" src={Gestacao} alt="" />
                  </figure>
                </div>
              </div>
            </div>

            <div className="report-gestation my-5">
              <div className="row">
                <div className="col-12 col-sm-12 col-mb-6 col-lg-6">
                  <div className="left">
                    <div className="text">
                      <h2>Agradecemos sua disponibilidade em relatar as informações sobre o andamento da sua gravidez e o nascimento dos bebês</h2>
                      <h5>Sinta-se à vontade para voltar a este espaço e atualizá-las sempre que puder.</h5>
                      <p>Todas as informações fornecidas ao Pro-Seed são confidenciais e ajudará a contabilizar o número de crianças nascidas de acordo com o doador, além de acompanhar os limites de nascimento.</p>
                    </div>
                    <div className="user-box my-5">
                      <div className="user-line d-flex justify-content-between">
                        <div className="user d-flex ">
                          <figure>
                            <img src={UserPhoto} alt="" />
                          </figure>
                          <div className="text">
                            <h6>Patricina Fernandez</h6>
                            <span>Paciente</span>
                          </div>
                        </div>
                        <button>
                          <img src={Maximize} alt="" />
                        </button>
                      </div>
                      <div className="clinic-line d-flex justify-content-start">
                        <div className="pais d-flex my-2">
                          <figure>
                            <img src={Pais} alt="" />
                          </figure>
                          <div className="text">
                            <h6>Curitiba</h6>
                            <span>Paraná - BR</span>
                          </div>
                        </div>
                        <div className="contact d-flex my-2 ml-5">
                          <figure>
                            <img src={Hospital} alt="" />
                          </figure>
                          <div className="text d-block">
                            <h6>(41) 99693-8565</h6>
                            <span>patricina.fernandez2023@gmail.com</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-mb-6 col-lg-6">
                  <div className="right">
                    <div className="box">
                      <div className="form-line my-2">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Qual nº do seu pedido <span>*</span></p>
                              </div>
                              <Select
                                className="react-select gestacao"
                                placeholder={<div>Selecione</div>}
                                components={animatedComponents}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={false}
                                isRtl={false}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Tipo de tratamento <span>*</span></p>
                              </div>
                              <Select
                                className="react-select gestacao"
                                placeholder={<div>Selecione</div>}
                                components={animatedComponents}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={false}
                                isRtl={false}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-line my-2">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Número do doador <span>*</span></p>
                              </div>
                              <input className="form-control textarea" type="textarea" placeholder="Selecione" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Resultado <span>*</span></p>
                              </div>
                              <Select
                                className="react-select gestacao"
                                placeholder={<div>Selecione</div>}
                                components={animatedComponents}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={false}
                                isRtl={false}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-line my-2">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Clínica onde fez tratamento <span>*</span></p>
                              </div>
                              <input className="form-control textarea" type="textarea" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Data de nascimento <span>*</span></p>
                              </div>
                              <Select
                                className="react-select gestacao"
                                placeholder={<div>Selecione</div>}
                                components={animatedComponents}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={false}
                                isRtl={false}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-line my-2">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Número de meninos <span>*</span></p>
                              </div>
                              <Select
                                className="react-select gestacao"
                                placeholder={<div>Selecione</div>}
                                components={animatedComponents}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={false}
                                isRtl={false}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="form-group">
                              <div className="text-box mb-2">
                                <p className="mb-0">Número de meninas <span>*</span></p>
                              </div>
                              <Select
                                className="react-select gestacao"
                                placeholder={<div>Selecione</div>}
                                components={animatedComponents}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={false}
                                isRtl={false}
                                closeMenuOnSelect={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-line textarea my-2">
                        <div className="form-group">
                          <div className="text-box mb-2">
                            <p className="mb-0">Comentário</p>
                          </div>
                          <input className="form-control" type="textarea" placeholder="Comentário" />
                        </div>
                      </div>
                      <div className="btn-group mt-5">
                        <button className="btn btn-primary">Enviar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </div>
  )
}

export default Report
