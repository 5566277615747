import '@resources/fontes/fontes.css'
import React from 'react'
import { Link } from 'react-router-dom'
import Banco from '@resources/imgs/Pagar-1.png'

const Pagar: React.FC<any> = () => {

  return (
    <>
      <div className="line-content pagar">
        <div className="check-sample d-flex justify-content-between">
          <div className="left-side">
            <figure>
              <img className="img-fluid" src={Banco} alt="" />
            </figure>
          </div>
          <div className="right-side">
            <div className="text mb-3">
              <p><span>Clique abaixo</span> e seja redirecionado para página do Pagar.me</p>
            </div>
            <Link to="/" className="btn btn-primary">
              <span>Realizar</span> pagamento Pagar.me
            </Link>
          </div>
        </div>
      </div>
    </>

  )
}

export default Pagar
