import React, { useState } from 'react'
import '@resources/css/tabs/general.css'
import IDonor from '@interfaces/IDonor'
import { t } from 'i18next'
import IExamDocument from '@interfaces/IExamDocument'
import { FaFile, FaImage } from 'react-icons/fa'
import pdf from '@resources/files/caracteristicas3.pdf'
import Lightbox from 'yet-another-react-lightbox'
import Download from 'yet-another-react-lightbox/plugins/download'
import Share from 'yet-another-react-lightbox/plugins/share'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'

type Props = {
  donor: IDonor
}

const General: React.FC<Props> = ({ donor }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<string>('')
  const [currentImageTitle, setCurrentImageTitle] = useState<string>('')

  const handleImageClick = (file: string, title: string) => {
    setCurrentImage(file)
    setCurrentImageTitle(title)
    setIsOpen(true)
  }

  return (
    <>
      <section className="donator-about">
        <div className="bio">
          <div className="row">
            <div className="col-12">
              <div className="row m-0">
                {donor.occupation && (
                  <div className="feature-item">
                    <h5 className="text-capitalize">Profissão</h5>
                    <p>{donor.occupation}</p>
                  </div>
                )}
                {donor.dominant_hand && (
                  <div className="feature-item">
                    <h5 className="text-capitalize">Mão dominante</h5>
                    <p>{t(`fields.${donor.dominant_hand}`)}</p>
                  </div>
                )}
                {donor.zodiac_sign && (
                  <div className="feature-item">
                    <h5 className="text-capitalize">Signo</h5>
                    <p>{donor.zodiac_sign.name}</p>
                  </div>
                )}
                {donor.religion && (
                  <div className="feature-item">
                    <h5 className="text-capitalize">Religião</h5>
                    <p>{donor.religion.name}</p>
                  </div>
                )}
                {donor.ethnic_groups?.length > 0 && (
                  <div className="feature-item">
                    <h5 className="text-capitalize">Origem / Ascendência</h5>
                    <p>{donor.ethnic_groups.join(', ')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {donor.complete_primary_education ||
          donor.complete_high_school ||
          donor.complete_university_education ||
          donor.has_specialization_course ||
          donor.has_masters_course ||
          donor.has_doctorate_course ||
          donor.has_mba_course ||
          donor.has_phd_course ||
          donor.other_courses ||
          donor.languages?.length > 0 ?
          (
            <div className="formation">
              <h5>Formação</h5>
              <div className="row">
                {donor.complete_primary_education || donor.complete_high_school || donor.complete_university_education || donor.has_specialization_course ? (
                  <div className="col-12 col-lg-6">
                    {donor.complete_primary_education ? (
                      <p><span className="text-capitalize">Ensino fundamental completo:</span> {donor.complete_primary_education ? 'Sim' : 'Não'}</p>
                    ) : null}
                    {donor.complete_high_school ? (
                      <p><span className="text-capitalize">Ensino médio completo:</span> {donor.complete_high_school ? 'Sim' : 'Não'}</p>
                    ) : null}
                    {donor.complete_university_education !== null && donor.university_education_description ? (
                      <p><span className="text-capitalize">Ensino superior {donor.complete_university_education ? 'completo' : 'incompleto'}:</span> {donor.university_education_description}</p>
                    ) : null}
                    {donor.has_specialization_course !== null && donor.specialization_course_description ? (
                      <p><span className="text-capitalize">Pós graduação/especialização {donor.has_specialization_course ? 'completo' : 'incompleto'}:</span> {donor.specialization_course_description}</p>
                    ) : null}
                  </div>
                ) : null}
                {donor.has_masters_course || donor.has_doctorate_course || donor.has_mba_course || donor.has_phd_course || donor.other_courses || donor.languages?.length > 0 ? (
                  <div className="col-12 col-lg-6">
                    {donor.has_masters_course !== null && donor.masters_course_description ? (
                      <p><span className="text-capitalize">Mestrado {donor.has_masters_course ? 'completo' : 'incompleto'}:</span> {donor.masters_course_description}</p>
                    ) : null}
                    {donor.has_doctorate_course !== null && donor.doctorate_course_description ? (
                      <p><span className="text-capitalize">Doutorado {donor.has_doctorate_course ? 'completo' : 'incompleto'}:</span> {donor.doctorate_course_description}</p>
                    ) : null}
                    {donor.has_mba_course !== null && donor.mba_course_description ? (
                      <p><span className="text-capitalize">MBA {donor.has_mba_course ? 'completo' : 'incompleto'}:</span> {donor.mba_course_description}</p>
                    ) : null}
                    {donor.has_phd_course !== null && donor.phd_course_description ? (
                      <p><span className="text-capitalize">PHD {donor.has_phd_course ? 'completo' : 'incompleto'}:</span> {donor.phd_course_description}</p>
                    ) : null}
                    {donor.other_courses ? (
                      <p><span className="text-capitalize">Outros cursos:</span> {donor.other_courses}</p>
                    ) : null}
                    {donor.languages?.length > 0 && (
                      <p><span className="text-capitalize">Idiomas:</span> {donor.languages.join(', ')}</p>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        {donor.hobbies || donor.like_to_travel || donor.favorite_destination || donor.exercise || donor.which_physical_exercises || donor.do_volunteer_work || donor.which_volunteer_work || donor.favorite_animal || donor.favorite_food || donor.favorite_color || donor.favorite_movie_genre || donor.favorite_music_type_singer_band_genre ? (
          <div className="activities">
            <h5>Personalidade e Preferências</h5>
            <div className="row">
              {donor.hobbies && (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Seus Hobbies preferidos:</span><br/> {donor.hobbies}</p>
                </div>
              )}
              {donor.like_to_travel ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Gosta de viajar:</span><br/> {donor.like_to_travel ? 'Sim' : 'Não'}</p>
                </div>
              ) : null}
              {donor.favorite_destination ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Destino favorito:</span><br/> {donor.favorite_destination}</p>
                </div>
              ) : null}
              {donor.exercise ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Pratica exercícios:</span><br/> {donor.exercise ? 'Sim' : 'Não'}</p>
                </div>
              ) : null}
              {donor.which_physical_exercises ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Qual exercício:</span><br/> {donor.which_physical_exercises}</p>
                </div>
              ) : null}
              {donor.do_volunteer_work ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Faz trabalhos voluntários:</span><br/> {donor.do_volunteer_work ? 'Sim' : 'Não'}</p>
                </div>
              ) : null}
              {donor.which_volunteer_work ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Quais trabalhos:</span><br/> {donor.which_volunteer_work}</p>
                </div>
              ) : null}
              {donor.favorite_animal ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Animal preferido:</span><br/> {donor.favorite_animal}</p>
                </div>
              ) : null}
              {donor.favorite_food ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Comida preferida:</span><br/> {donor.favorite_food}</p>
                </div>
              ) : null}
              {donor.favorite_color ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor favorita:</span><br/> {donor.favorite_color}</p>
                </div>
              ) : null}
              {donor.favorite_movie_genre ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Gênero de filme favorito:</span><br/> {donor.favorite_movie_genre}</p>
                </div>
              ) : null}
              {donor.favorite_music_type_singer_band_genre ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Tipo de música, cantor(a), banda ou gênero favorito:</span><br/> {donor.favorite_music_type_singer_band_genre}</p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {donor.about_donor_documents && donor.about_donor_documents.length > 0 && (
          <div className="content exam-documents">
            <h5 className="mb-4">Arquivos do Doador</h5>
            <div className="">
              {donor.about_donor_documents.map((doc: IExamDocument) => (
                <div className="exam-document-item text-center" key={doc.id}>
                  <p className="mb-0"><span>{doc.name}</span></p>
                  {doc.type === 'pdf' ? (
                    <a href={doc.file} target="_blank" rel="noreferrer">
                      <button className="btn btn-outline-secondary d-flex justify-content-center align-items-center">
                        <FaFile />
                      </button>
                    </a>
                  ) : (
                    <button
                      className="btn btn-outline-secondary d-flex justify-content-center align-items-center"
                      onClick={() => handleImageClick(doc.file, doc.name)}
                    >
                      <FaImage />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {isOpen && (
          <Lightbox
            open={isOpen}
            close={() => setIsOpen(false)}
            slides={[{ src: currentImage, title: currentImageTitle }]}
            carousel={{ finite: true, }}
            plugins={[Download, Share, Captions, Zoom]}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        )}
      </section>

      <section className="know-about-caracteristics">
        <div className="row align-items-center">
          <div className="col-12 col-md-8 text-center text-md-left">
            <h5 className="mb-0">Quer entender melhor as características do Doador?</h5>
          </div>
          <div className="col-12 col-sm-12 col-md-4 text-center text-md-right mt-2 mt-md-0">
            <a href={pdf} target="_blank" rel="noreferrer">
              <button className="btn btn-primary">Características</button>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default General
