import '@resources/fontes/fontes.css'
import '@resources/css/cardsample/cardsample.css'
import React from 'react'
import { Card } from 'react-bootstrap'
import { IoIosAlert, IoIosArrowDown, IoIosArrowUp, IoIosCheckmark } from 'react-icons/io'
import { IoIosMove } from 'react-icons/io'
import { IoTrash } from 'react-icons/io5'
import Tratamento from '@resources/imgs/esperma-1.svg'
import Photo from '@resources/imgs/mask-group-2.png'
import LogoProssed from '@resources/imgs/logo-proseed-2.png'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface CardSampleProps {
  hasProperty: boolean
  canDrag: boolean
  canSelect: boolean
  item: any
  size: boolean
  selectedTreatments?: any
  handleMoveUp?: (id: string | number) => void
  handleMoveDown?: (id: string | number) => void
}
const CardSample: React.FC<any> = ({ canSelect, hasProperty, canDrag, item, selectedTreatments, handleMoveUp, handleMoveDown }: CardSampleProps) => {

  const { listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })
  const isTreatmentEligible = selectedTreatments?.includes(item.treatment)
  return (
    <div className="col-md-4 box-card"
      ref={setNodeRef}
      {...listeners}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition
      }}
    >
      <Card className={`donator-card my-3 ${isTreatmentEligible ? 'border-none' : 'border-red'}`}>
        <Card.Header className="card-header-donator d-flex justify-content-between" id="card-swiper">
          <div className="top-icons d-flex">
            {canSelect && (
              <div className="check mx-1">
                <input type="checkbox" className="donor-checkbox" name="select" id="select" />
              </div>
            )}
            {hasProperty && (
              <div className="priority mx-1">
                Prioridade
                <span>{item.id}</span>
              </div>
            )}
            {canDrag && (
              <div className="move-item">
                <button className="icon" onClick={() => handleMoveUp?.(item.id)}>
                  <IoIosArrowUp />
                </button>
                <button className="icon" onClick={() => handleMoveDown?.(item.id)}>
                  <IoIosArrowDown />
                </button>
              </div>
            )}
            <button className="icons trash-icon mx-1 border-0">
              <IoTrash />
            </button>
          </div>
          <div className="price">
            R$ 2.688,00
          </div>
        </Card.Header>

        <Card.Body className="card-body-donator d-flex align-items-center">
          <figure className="d-flex justify-content-center mb-0">
            <img className="photo" src={Photo} alt="" />
          </figure>
          <div className="d-flex flex-column justify-content-center p-3">
            <div id="dados">
              <div className="id">ID: {item.id}</div>
              <div className="amostra">Amostra Nacional</div>
            </div>
            {!isTreatmentEligible && (
              <p className="not-eligible">Doador incompátivel</p>
            )}
            <div className="tratament-logo d-flex align-items-center justify-content-between">
              <div className="tratament d-flex align-items-center justify-content-between">
                <img src={Tratamento} alt="" />
                <span>FIV</span>
              </div>
              <figure className="mb-0">
                <img src={LogoProssed} alt="" />
              </figure>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default CardSample
