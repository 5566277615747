import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Corrida from './../../resources/imgs/Pro-Seed-Corrida-dos-Espermas-.png'

const SummaryModal: React.FC<any> = ({ showModal, handleCloseModal }) => {

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Body>
          <figure>
            <img src={Corrida} alt="" />
          </figure>
          <h3>Muito bem! Agora é só acompanhar</h3>
          <p>Sua solicitação foi realizada, siga os próximos passos para finalizar e receber sua amostra.</p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/pedidos" className="btn-obg btn btn-primary">Ok! obrigado.</Link>
        </Modal.Footer>
      </Modal>
    </>

  )
}

export default SummaryModal
