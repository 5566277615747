import '@resources/fontes/fontes.css'
import React from 'react'

const Families: React.FC<any> = () => {

  return (
    <>
      <div className="line-content families">
        <div className="check-sample">
          <div className="text">
            <p>A Families é uma plataforma digital para facilitar o proceso de contração de empréstimos, que atua como correspondente bancário nos diretrizes do Banco Central do Brasil, nos termos da resolução nº 3.954, 24 de fevereiro de 2011.</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Families
