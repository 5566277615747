import FloatWhatsappButton from '@components/FloatWhatsappButton/FloatWhatsappButton'
import PanelContext from '@contexts/Panel'
import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

const App: React.FC<any> = () => {
  const { collapsed } = useContext(PanelContext)

  return (
    <>
      <div className={'main-wrapper' + (collapsed ? ' sidebar-mini' : '')}>
        <div className="main-content">
          <Outlet />
        </div>

        <FloatWhatsappButton
          whatsappNumber="5511983180033"
          text="Gostaria de reservar um doador."
          cta="Faça sua reserva!"
        />
      </div>
    </>
  )
}

export default App
