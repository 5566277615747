const cnpj = (cnpj: string) => {
  return cnpj
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const cpf = (cpf: string) => {
  return cpf
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
}

const zipCode = (zipCode: string) => {
  return zipCode
    .replace(/\D+/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
}

const cellphone = (cellphone: string, requiredPrefix = false) => {
  cellphone = cellphone.replace(/\D+/g, '')

  if (requiredPrefix)
    cellphone = cellphone.replace(/(\d{2})(\d)/, '($1) $2')

  return cellphone.replace(/(\d{1})(\d{4})(\d)/, '$1 $2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const telephone = (telephone: string, requiredPrefix = false) => {
  telephone = telephone.replace(/\D+/g, '')

  if (requiredPrefix)
    telephone = telephone.replace(/(\d{2})(\d)/, '($1) $2')

  return telephone.replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const time = (time: string) => {
  return time
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2')
}

const money = (value: string) => {
  value = value.length === 0 ? '0,00' : value.indexOf('.') !== -1 && value.split('.')[1].length === 1 ? `${value}0` : value
  value = value.replace(/(?!^-)[^0-9]/g, '')
  value = value.length === 0 ? '0,00' : value.length === 1 ? `0,0${value}` : value.length === 2 ? `0,${value}` : `${value.slice(0, value.length - 2)},${value.slice(value.length - 2)}`

  const values = value.split(',')

  return `${(parseInt(values[0], 10).toString()).split(/(?=(?:...)*$)/).join('.')},${values[1] || ''}`
}

const cpfOrCnpj = (cpfOrCnpj: string) => {
  const value = cpfOrCnpj.replace(/\D+/g, '')

  if (value.length <= 11)
    return cpf(cpfOrCnpj)

  return cnpj(cpfOrCnpj)
}

const phone = (phone: string, requiredPrefix = false) => {
  if (phone.length <= 14)
    return telephone(phone, requiredPrefix)

  return cellphone(phone, requiredPrefix)
}

const Mask = {
  cpf,
  cnpj,
  cellphone,
  telephone,
  zipCode,
  time,
  money,
  cpfOrCnpj,
  phone,
}

export default Mask
