import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const SpinnerContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  z-index: 9999;
`

export const SpinnerIcon = styled.div`
  width: 100px;
  height: 100px;
  animation: ${rotate} 1s linear infinite;
`

export const SpinnerText = styled.p`
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
`