import '@resources/fontes/fontes.css'
import '@resources/css/report.css'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import React, { useCallback, useState } from 'react'
import CardSample from '@components/Cards/CardSample/CardSample'
import { BsChevronRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import {
  DndContext,
  useSensors,
  useSensor,
  closestCenter,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
} from '@dnd-kit/sortable'

const Favorites: React.FC<any> = () => {
  const navigate = useNavigate()
  const [selectedTreatments, setSelectedTreatments] = useState(['FIV'])

  // DND consts
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [activeId, setActiveId] = useState<string | null>(null)
  const [selectedDonors, setSelectedDonors] = useState([
    { id: '1', treatment: 'FIV' },
    { id: '2', treatment: 'FIV' },
    { id: '3', treatment: 'FIV' },
    { id: '4', treatment: 'FIV' },
    { id: '5', treatment: 'FIV' },
  ])

  const handleDragStart = useCallback((event: DragStartEvent) => {
    return setActiveId(event.active.id.toString())
  }, [])
  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setSelectedDonors((donor) => {
        const oldIndex = donor.findIndex(g => g.id === active.id.toString())
        const newIndex = donor.findIndex(g => g.id === over!.id.toString())

        return arrayMove(donor, oldIndex, newIndex)
      })
    }

    setActiveId(null)
  }, [])
  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  return (
    <div className="favorites">
      <Sidebar />
      <main className="with-fixed-nav">

        <div className="container">
          <div className="row align-items-center justify-content-between mt-3 mb-3">
            <div className="col-7 col-sm-7 col-md-3 d-none d-md-block">
              <div className="row m-0">
                <div className="col-auto pr-0">
                  <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                    Home
                  </a>
                </div>
                <div className="col-auto pl-1 pr-1">
                  <BsChevronRight size={10} />
                </div>
                <div className="col-auto p-0">
                  <a href="#" className="id d-flex align-items-center font-weight-bold">
                    Favoritos
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-7 col-md-3 pl-5">
              <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
            </div>
            <div className="col-5 col-sm-5 col-md-6">
              <Header />
            </div>
          </div>
          <div className="pt-2 pb-2 d-block d-md-none">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Home
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Solicitação de pedidos
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
            >
              <SortableContext items={selectedDonors}>
                {selectedDonors.map((donor) => (
                  <CardSample key={donor} canDrag={true} item={donor} selectedTreatments={selectedTreatments} />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Favorites