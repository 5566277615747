import styled from 'styled-components'

export const FloatButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #128c7e;
    color: #fff;
  }
`

export const FloatIcon = styled.img`
  width: 30px;
  height: 30px;
`
export const FloatText = styled.div`
    position: absolute;
    right: 130%;
    color: #000;
    font-size: 12px;
    white-space: nowrap;
    background-color: #fff;
    padding: 11px;
    border-radius: 10px;
    filter: drop-shadow(2px 0px 5px rgba(130,130,130,.3));
    font-weight: 700;

    span{
      position: relative;
      z-index: 1;
    }
    
    &::after{
        content: '';
          width: 20px;
          height: 20px;
          right: -6px;
          position: absolute;
          background-color: #ffffff;
          border-radius: 5px;
          transform: rotate(45deg) translate(-1px, -1px);
      }
`
export const FloatIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
