export const random = (): number => parseInt((Math.random() * 100000).toFixed(0))

export const generateToken = (size: number): string => {
  return new Array(size).join().replace(/(.|$)/g, () => ((Math.random()*36)|0).toString(36)[Math.random()<.5?'toString':'toUpperCase']())
}

export const toBRLPrice = (price: number) => {
  if (isNaN(price))
    return '0,00'

  return new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(price)
}

export const downloadDataURL = (dataURL: string, fileName?: string) => {
  if (!fileName) {
    const arr = dataURL.split('/')
    fileName = arr[arr.length - 1]
  }

  const link = document.createElement('a')
  link.download = fileName
  link.href = dataURL
  link.target = '_blank'
  link.click()
}

export const decompose = (matrix: number[]) => {
  const a = matrix[0]
  const b = matrix[1]
  const c = matrix[2]
  const d = matrix[3]
  const e = matrix[4]
  const f = matrix[5]

  const delta = a * d - b * c

  const result = {
    x: e,
    y: f,
    rotation: 0,
    scaleX: 0,
    scaleY: 0,
    skewX: 0,
    skewY: 0,
  }

  if (a != 0 || b != 0) {
    const r = Math.sqrt(a * a + b * b)
    result.rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r)
    result.scaleX = r
    result.scaleY = delta / r
    result.skewX = Math.atan((a * c + b * d) / (r * r))
    result.skewY = 0
  } else if (c != 0 || d != 0) {
    const s = Math.sqrt(c * c + d * d)
    result.rotation = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s))
    result.scaleX = delta / s
    result.scaleY = s
    result.skewX = 0
    result.skewY = Math.atan((a * c + b * d) / (s * s))
  }

  result.rotation *= 180 / Math.PI

  return result
}

export const toDateString = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
}

export const cpfIsValid = (cpf: string) => {
  if (!cpf) return false

  cpf = cpf.replace(/[^\d]+/g, '')

  if (cpf.length !== 11) return false

  if (cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') return false

  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9))) return false

  add = 0
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10))) return false

  return true
}

export const maskName = (name: string): string => {
  return name.split(' ').map(part => {
    if (part.length > 2) {
      return part.slice(0, 2) + '*'.repeat(part.length - 2)
    }
    return part
  }).join(' ')
}