import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import { Spinner } from 'react-bootstrap'
import LoginContext from '@contexts/Login'
import Corrida from '@resources/imgs/corrida-esperma.png'
import Logoprossed from '@resources/imgs/logo-proseed-1.png'
import SMS from '@resources/imgs/sms.png'
import LOCK from '@resources/imgs/lock.png'
import Image from '@resources/imgs/image.png'
import '@resources/css/login.css'
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import axios from 'axios'
import AuthContext from '@contexts/Auth'

type Props = {
  donorId?: string
}

const Login: React.FC<Props> = ({ donorId }) => {
  const { setUser, setToken, setIsAuthenticated } = useContext(AuthContext)
  const { email, setEmail, password, setPassword, isLoading, setIsLoading, error, setError } = useContext(LoginContext)
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.login(email, password).then(({ data: { user, token } }) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setIsAuthenticated(true)
      navigate(`/doadores/${donorId?.toLowerCase()}`)

    }).catch((e) => {
      setError(
        e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.'
      )
    }).finally(() => setIsLoading(false))
  }

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  return (
    <div className="container">
      <div className="login-modal mb-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="left-side">
              <figure>
                <img src={Corrida} alt="Corrida de Espermas" />
              </figure>
              <div className="text-area">
                <h2>Quero me cadastrar!</h2>
                <p>Cadastre-se para ter <span>acesso gratuito e ilimitado</span> aos perfis completos de +280 doadores nacionais, americanos e europeus à pronta entrega!</p>
              </div>
              <Link className="btn btn-primary mt-3" to={`/cadastre-se?doador=${donorId}`}>Fazer meu cadastro</Link>
            </div>

          </div>
          <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-between">
            <div className="logo">
              <figure>
                <img src={Logoprossed} alt="Logo Prossed" />
              </figure>
            </div>
            {error && (
              <div className="alert alert-danger" role="alert">
                <strong>Atenção!</strong> {error}
              </div>
            )}

            <form onSubmit={onSubmit} className="lg-w-auto">
              <div className="text-login">
                <h2>Já sou cadastrada – fazer login:</h2>
              </div>
              <div className="form-floating has-validation">
                <div className="inputs d-flex justify-content-between">
                  <i className="sms-icon"><img src={SMS} alt="" /></i>
                  <input
                    type="email"
                    className={'form-control mb-3 custom-placeholder' + (error ? ' is-invalid' : '')}
                    id="email"
                    placeholder="Insira seu e-mail *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isLoading}
                    required
                  />
                </div>
                <div className="inputs d-flex justify-content-between">
                  <i className="pw-icon"><img src={LOCK} alt="" /></i>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className={'form-control mb-3 custom-placeholder' + (error ? ' is-invalid' : '')}
                    id="password"
                    placeholder="Insira sua senha *"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                    required
                  />
                  <button
                    className={`btn eye-icon px-3 ${error ? 'mr-3' : ''}`}
                    onClick={(e) => togglePasswordVisibility(e)}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="closed-eye-icon" />
                    ) : (
                      <FaEye className="open-eye-icon" />
                    )}
                  </button>
                </div>
                <div className="btn-and-lostpw text-right">
                  <Link to="/recuperar-senha">Esqueceu sua senha?</Link>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isLoading}
                  >
                      Acessar {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
