import React from 'react'
import { FloatButton, FloatText } from './FloatWhatsappButton.styles'
import { FaWhatsapp } from 'react-icons/fa'

interface FloatWhatsappButtonProps {
  whatsappNumber: string
  text?: string
  cta?: string
}

const FloatWhatsappButton: React.FC<FloatWhatsappButtonProps> = ({ whatsappNumber, text, cta }) => {

  whatsappNumber = whatsappNumber.replace(/\D/g, '')

  const link = `https://wa.me/${whatsappNumber}${text ? `?text=${encodeURIComponent(text)}` : ''}`

  return (
    <FloatButton href={link} target="_blank">
      {cta && (
        <FloatText><span>{cta}</span></FloatText>
      )}
      <FaWhatsapp size={30} />
    </FloatButton>
  )
}

export default FloatWhatsappButton
