import LogoProssed from '@resources/imgs/logo-proseed-2.png'
import '@resources/fontes/fontes.css'
import '@resources/css/donators.css'
import '@resources/css/patient.css'
import React, { useState, useEffect, useCallback } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { HiOutlineHandThumbUp } from 'react-icons/hi2'
import makeAnimated from 'react-select/animated'
import '@resources/fontes/fontes.css'
import 'swiper/css/navigation'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import { useNavigate } from 'react-router-dom'
import ClinicSelectModal from '@components/Modals/ClinicSelectModal'
import CardSample from '@components/Cards/CardSample/CardSample'
import { GoPlusCircle } from 'react-icons/go'
import { BsChevronRight } from 'react-icons/bs'

const tratamento = [
  { value: 'FIV', label: 'FIV', },
  { value: 'ICSI com preparo seminal (SP)', label: 'ICSI com preparo seminal (SP)', },
]

const origem = [
  { value: 'FIV', label: 'FIV', },
  { value: 'ICSI com preparo seminal (SP)', label: 'ICSI com preparo seminal (SP)', },
]

const Patient: React.FC<any> = () => {
  const [showModal, setShowModal] = useState(false)
  const [exibirAlertaSuccess, setExibirAlertaSuccess] = useState(false)
  const [selectedTreatments, setSelectedTreatments] = useState(['FIV'])

  // DND consts
  const [selectedDonors, setSelectedDonors] = useState([
    { id: '1', treatment: 'FIV' },
    { id: '2', treatment: 'FIV' },
    { id: '3', treatment: 'FIV' },
    { id: '4', treatment: 'FIV' },
    { id: '5', treatment: 'FIV' },
  ])

  useEffect(() => {
    setExibirAlertaSuccess(selectedDonors.length >= 2)
  }, [selectedDonors])

  const [showSelectModal, setShowSelectModal] = useState(false)

  const handleShowSelectModal = () => {
    setShowSelectModal(true)
  }

  const handleCloseSelectModal = () => {
    setShowSelectModal(false)
  }

  const handleMoveUp = (id: string) => {
    setSelectedDonors(prevDonors => {
      const index = prevDonors.findIndex(donor => donor.id === id)
      if (index <= 0) return prevDonors

      const newDonors = [...prevDonors]
      const temp = newDonors[index]
      newDonors[index] = newDonors[index - 1]
      newDonors[index - 1] = temp

      return newDonors
    })
  }

  const handleMoveDown = (id: string) => {
    setSelectedDonors(prevDonors => {
      const index = prevDonors.findIndex(donor => donor.id === id)
      if (index === -1 || index === prevDonors.length - 1) return prevDonors

      const newDonors = [...prevDonors]
      const temp = newDonors[index]
      newDonors[index] = newDonors[index + 1]
      newDonors[index + 1] = temp

      return newDonors
    })
  }

  const navigate = useNavigate()

  return (
    <div className="patient-page">
      <Sidebar />
      <main className="with-fixed-nav">
        <div className="container">
          <div className="row  align-items-center justify-content-between mt-3 mb-3">
            <div className="col-7 col-sm-7 col-md-4 d-none d-md-block">
              <div className="row m-0">
                <div className="col-auto pr-0">
                  <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                    Home
                  </a>
                </div>
                <div className="col-auto pl-1 pr-1">
                  <BsChevronRight size={10} />
                </div>
                <div className="col-auto p-0">
                  <a href="#" className="id d-flex align-items-center font-weight-bold">
                    Solicitação de pedidos
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-7 col-md-3 pl-5">
              <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
            </div>
            <div className="col-5 col-sm-5 col-md-4">
              <Header />
            </div>
          </div>
          <div className="pt-2 pb-2 d-block d-md-none">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Home
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Solicitação de pedidos
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <section className="box-card py-3">
            <div className="baby d-mb-block d-flex justify-content-between align-items-center">
              <div className="text">
                <h5>Bebê a bordo!</h5>
                <p>Você pode iniciar uma solicitação por aqui, basta clicar em quais amostras gostaria de solicitar.</p>
              </div>
              <button className="btn btn-primary d-flex align-items-center justify-content-around" onClick={handleShowSelectModal}>
                Fazer uma solicitação
                <GoPlusCircle />
                <ClinicSelectModal showModal={showSelectModal} handleCloseModal={handleCloseSelectModal} />
              </button>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={`w-100 alert alert-primary mt-3 ${selectedDonors.length <= 2 ? 'd-block' : 'd-none'}`}>
                  <div className="d-flex align-items-center">
                    <FiAlertTriangle />
                    <p className="mb-0 ml-3">Será necessário no mínimo a escolha de dois doadores e no máximo de cinco, fique a vontade para trocar.</p>
                  </div>
                </div>

                <div className={`w-100 alert alert-success mt-3 ${selectedDonors.length >= 2 ? 'd-block' : 'd-none'}`}>
                  <div className="d-flex align-items-center">
                    <HiOutlineHandThumbUp />
                    <p className="mb-0 ml-3">Com 2 doadores já está liberado para fazer a solicitação das amostras.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row change-order-position">
              {selectedDonors.map((donor) => (
                <CardSample
                  key={donor.id}
                  canDrag={true}
                  item={donor}
                  selectedTreatments={selectedTreatments}
                  handleMoveUp={handleMoveUp}
                  handleMoveDown={handleMoveDown}
                />
              ))}
            </div>
          </section>
        </div>
      </main >
    </div >
  )
}

export default Patient
