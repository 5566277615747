import React from 'react'
import makeAnimated from 'react-select/animated'
import { Card } from 'react-bootstrap'
import Foto1 from '@resources/imgs/card_foto_1.png'
import '@resources/css/cardabout/cardabout.css'

const animatedComponents = makeAnimated()

interface CardAboutProps {
  img: string;
  title: string;
  subtitle: string;
  content: string;
}

const CardAbout: React.FC<any> = ({title, subtitle, content}: CardAboutProps) => {

  return (
    <>
      <Card className="card-box">
        <Card.Header>
          <figure>
            <img src={Foto1} alt="" />
          </figure>
        </Card.Header>
        <Card.Body>
          <h4>{title}</h4>
          <h5>{subtitle}</h5>
        </Card.Body>
        <Card.Footer>
          <p>{content}</p>
        </Card.Footer>
      </Card>
    </>
  )
}

export default CardAbout
