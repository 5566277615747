export const formDataReduce = (formData: FormData) => Array.from(formData.entries()).reduce((acc: any, [key, value]) => {
  const [name, field] = key.split(/\[|\]/)

  if (typeof field === 'undefined') {
    acc[name] = value
  } else {
    acc[name] = acc[name] || []
    acc[name].push(value)
  }

  return acc
}, {})

const joinValue = (value: any) => (value as string[]).filter(value => {
  return value?.length > 0 || value
}).map(value => String(value)).join(',')

export const setEntriesToSearchParams = (searchParams: URLSearchParams, data: any) => {
  const entries = Object.entries(data)

  Array.from(searchParams.keys()).filter(value => !['sort_by', 'order'].includes(value) ).forEach(value => searchParams.delete(value))

  // searchParams.set('page', '1')

  entries.forEach(([key, value]) => {
    value = typeof value === 'number' ? String(value) : value

    if ((typeof value === 'string' && value != '0' && value.length > 0) || (Array.isArray(value) && value.length > 0 && (value[0]?.length > 0 || value[0]))) {
      searchParams.set(key, typeof value === 'string' ? value : joinValue(value))
    } else {
      searchParams.delete(key)
    }
  })
}

export const deleteAllSearchParams = (searchParams: URLSearchParams) => {
  Array.from(searchParams.keys()).forEach(value => searchParams.delete(value))
}