import React from 'react'
import '@resources/css/tabs/history.css'
import IDonor from '@interfaces/IDonor'

type Props = {
  donor: IDonor
}

const History: React.FC<Props> = ({ donor }) => {
  return (

    <section className="donator-about">
      {donor.is_smoker || donor.smoking_time || donor.wear_glasses_or_lenses || donor.has_hearing_impairment || donor.have_any_allergies || donor.regular_dental_checkup || donor.other_health_situations || donor.drink_alcohol ? (
        <div className="health">
          <h5>Saúde Geral do Doador</h5>
          <div className="row">
            {donor.is_smoker ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Fumante:</span><br /> {donor.is_smoker ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.smoking_time ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Quanto tempo?</span><br /> {donor.smoking_time}</p>
              </div>
            ) : null}
            {donor.drink_alcohol ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Bebida Alcoólica?</span><br /> {donor.drink_alcohol}</p>
              </div>
            ) : null}
            {donor.wear_glasses_or_lenses ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Deficiência visual:</span><br /> {donor.wear_glasses_or_lenses ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.wear_glasses_or_lenses ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Tipo de correção?</span><br /> {donor.type_of_eye_correction}</p>
              </div>
            ) : null}
            {donor.has_hearing_impairment ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Tem alguma deficiência auditiva:</span><br /> {donor.has_hearing_impairment ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.what_hearing_impairment ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Qual deficiência auditiva?</span><br /> {donor.what_hearing_impairment}</p>
              </div>
            ) : null}
            {donor.have_any_allergies ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Tem alergia:</span><br /> {donor.have_any_allergies ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.what_allergy ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Qual alergia?</span><br /> {donor.what_allergy}</p>
              </div>
            ) : null}
            {donor.regular_dental_checkup ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Vai ao dentista regularmente:</span><br /> {donor.regular_dental_checkup ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.other_health_situations ? (
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Outras situações de saúde:</span><br /> {donor.other_health_situations}</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {donor.has_children || donor.twins_in_family || donor.number_of_twins || donor.twin_relationship_degree || donor.black_people_in_family || donor.black_relationship_degree || donor.has_pregnancy_cases ? (
        <div className="transmissible">
          <h5>Histórico Familiar</h5>
          <div className="row content-line justify-content-between">
            {donor.has_children ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Tem filhos:</span><br /> {donor.has_children ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.twins_in_family ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Gêmeos na família:</span><br /> {donor.twins_in_family ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.number_of_twins ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Quantos gêmeos:</span><br /> {donor.number_of_twins}</p>
              </div>
            ) : null}
            {donor.twin_relationship_degree ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Qual grau de parentesco:</span><br /> {donor.twin_relationship_degree}</p>
              </div>
            ) : null}
            {donor.black_people_in_family ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Negros na família:</span><br /> {donor.black_people_in_family ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
            {donor.black_relationship_degree ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Qual grau de parentesco?</span><br /> {donor.black_relationship_degree}</p>
              </div>
            ) : null}
            {donor.has_pregnancy_cases !== null ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">Relatos de gravidez?</span><br /> {donor.has_pregnancy_cases == true ? 'Sim' : 'Não'}</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {donor.father_description ||
        donor.father_country ||
        donor.father_paternal_grandmother_country ||
        donor.father_paternal_grandfather_country ||
        donor.father_skin_color ||
        donor.father_eye_color ||
        donor.father_hair_color ||
        donor.father_hair_type ||
        donor.father_nose_shape ||
        donor.father_lip ? (
          <div className="triage">
            <h5>Caraterísticas e Informações do Pai</h5>
            <div className="row">
              {donor.father_description ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Descrição:</span><br /> {donor.father_description}</p>
                </div>
              ) : null}
              {donor.father_country ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">País de origem:</span><br /> {donor.father_country}</p>
                </div>
              ) : null}
              {donor.father_paternal_grandmother_country ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">País de origem da avó paterna:</span><br /> {donor.father_paternal_grandmother_country}</p>
                </div>
              ) : null}
              {donor.father_paternal_grandfather_country ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">País de origem do avô paterno:</span><br /> {donor.father_paternal_grandfather_country}</p>
                </div>
              ) : null}
              {donor.father_skin_color?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor da pele:</span><br /> {donor.father_skin_color?.name}</p>
                </div>
              ) : null}
              {donor.father_eye_color?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor dos olhos:</span><br /> {donor.father_eye_color?.name}</p>
                </div>
              ) : null}
              {donor.father_hair_color?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor do cabelo:</span><br /> {donor.father_hair_color?.name}</p>
                </div>
              ) : null}
              {donor.father_hair_type?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Tipo de cabelo:</span><br /> {donor.father_hair_type?.name}</p>
                </div>
              ) : null}
              {donor.father_nose_shape ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Formato do nariz:</span><br /> {donor.father_nose_shape}</p>
                </div>
              ) : null}
              {donor.father_lip?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Lábios:</span><br /> {donor.father_lip?.name}</p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      {donor.mother_description ||
        donor.mother_country ||
        donor.mother_maternal_grandmother_country ||
        donor.mother_maternal_grandfather_country ||
        donor.mother_skin_color ||
        donor.mother_eye_color ||
        donor.mother_hair_color ||
        donor.mother_hair_type ||
        donor.mother_nose_shape ||
        donor.mother_lip ? (
          <div className="genetic">
            <h5>Caraterísticas e Informações da Mãe</h5>
            <div className="row">
              {donor.mother_description ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Descrição:</span><br /> {donor.mother_description}</p>
                </div>
              ) : null}
              {donor.mother_country ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">País de origem:</span><br /> {donor.mother_country}</p>
                </div>
              ) : null}
              {donor.mother_maternal_grandmother_country ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">País de origem da avó paterna:</span><br /> {donor.mother_maternal_grandmother_country}</p>
                </div>
              ) : null}
              {donor.mother_maternal_grandfather_country ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">País de origem do avô paterno:</span><br /> {donor.mother_maternal_grandfather_country}</p>
                </div>
              ) : null}
              {donor.mother_skin_color?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor da pele:</span><br /> {donor.mother_skin_color?.name}</p>
                </div>
              ) : null}
              {donor.mother_eye_color?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor dos olhos:</span><br /> {donor.mother_eye_color?.name}</p>
                </div>
              ) : null}
              {donor.mother_hair_color?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Cor do cabelo:</span><br /> {donor.mother_hair_color?.name}</p>
                </div>
              ) : null}
              {donor.mother_hair_type?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Tipo de cabelo:</span><br /> {donor.mother_hair_type?.name}</p>
                </div>
              ) : null}
              {donor.mother_nose_shape ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Formato do nariz:</span><br /> {donor.mother_nose_shape}</p>
                </div>
              ) : null}
              {donor.mother_lip?.name ? (
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Lábios:</span><br /> {donor.mother_lip?.name}</p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      {donor.great_grandparents_origin_countries?.length > 0 || donor.great_great_grandparents_origin_countries?.length > 0 ? (
        <div className="genetic">
          <h5>Origem dos Bisavós e Tataravós</h5>
          <div className="row">
            {donor.great_grandparents_origin_countries?.length > 0 ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">País de origem dos bisavós:</span><br /> {donor.great_grandparents_origin_countries.join(', ')}</p>
              </div>
            ) : null}
            {donor.great_great_grandparents_origin_countries?.length > 0 ? (
              <div className="col-12 col-lg-6 content">
                <p><span className="text-capitalize">País de origem dos tataravós:</span><br /> {donor.great_great_grandparents_origin_countries.join(', ')}</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </section>
  )
}

export default History
