import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as $Auth from '@services/Auth'

const ForgotPassword: React.FC<any> = () => {
  const [ email, setEmail ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSent, setIsSent ] = useState(false)
  const [ error, setError ] = useState<string|null>(null)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.forgotPassword(email).then(() => {
      setError(null)
      setIsSent(true)
    }).catch(e => {
      setIsSent(false)
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar a recuperação de senha. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-4 offset-md-4">
        <div className="card">
          <div className="card-header">
            <h1 className="h6 w-100 d-block text-center mb-0">
              Informe o seu email para a recuperação de senha.
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              {isSent && (
                <div className="alert alert-success" role="alert">
                  Foi enviado um email para <strong>{email}</strong> com instruções para recuperar a sua senha.
                </div>
              )}

              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}

              <div className="form-floating has-validation">
                <label htmlFor="email">Seu endereço de e-mail</label>
                <input
                  type="email"
                  className={'form-control' + (error ? ' is-invalid' : '')}
                  id="email"
                  placeholder="name@examplo.com"
                  onChange={e => setEmail(e.target.value)}
                  disabled={isLoading}
                  required
                />
              </div>
            </div>

            <div className="card-footer">
              <button type="submit" className="btn btn-primary btn-block d-flex align-items-center justify-content-center" disabled={isLoading}>
                <span>Receber link de recuperação</span> {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
              </button>
            </div>
          </form>
        </div>

        <div className="text-center">
          <h5>Lembrou a sua senha?</h5>
          <Link to="/login">Efetue o login</Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
