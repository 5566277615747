import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Corrida from './../../resources/imgs/Pro-Seed-Corrida-dos-Espermas-.png'
import '@resources/css/loginsucessmodal.css'

const LoginSuccessModal: React.FC<any> = ({ showModal, handleCloseModal }) => {

  return (

    <>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Body>
          <figure>
            <img src={Corrida} alt="" />
          </figure>
          <h5>Agora você tem acesso total a nossa plataforma de busca de doadores, aproveite.</h5>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/doadores/1" className="btn-obg btn btn-primary">Entendido, obrigado!</Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LoginSuccessModal
