import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import * as $Auth from '@services/Auth'

const ResetPassword: React.FC<any> = () => {
  const [ email, setEmail ] = useState<string>('')
  const [ password, setPassword ] = useState<string>('')
  const [ passwordConfirmation, setPasswordConfirmation ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState<string|null>(null)
  const [ isSuccessful, setIsSuccessful ] = useState(false)

  const { search } = useLocation()

  const token = new URLSearchParams(search).get('token')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.resetPassword(token as string, email, password, passwordConfirmation).then(() => {
      setIsSuccessful(true)
      setError(null)
    }).catch(e => {
      setIsSuccessful(false)
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar a redefinição de senha. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-4 offset-md-4">
        <div className="card">
          <div className="card-header">
            <h1 className="h6 w-100 d-block text-center mb-0">Redefinição de senha</h1>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="card-body">
              {isSuccessful && (
                <div className="alert alert-success mb-0" role="alert">
                  A sua senha foi alterada com sucesso. <Link to="/login" className="alert-link">Clique aqui</Link> para efetuar o login.
                </div>
              )}

              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}

              {!isSuccessful && (
                <>
                  <div className="form-group has-validation mb-3">
                    <label htmlFor="email" className="mb-0">Seu endereço de e-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="name@example.com"
                      onChange={e => setEmail(e.target.value)}
                      disabled={isLoading}
                      required
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="password" className="mb-0">Sua nova senha</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Senha"
                      onChange={e => setPassword(e.target.value)}
                      disabled={isLoading}
                      required
                    />
                    <i className="form-text">(mínimo de 6 caracteres)</i>
                  </div>
                  <div className="form-group mb-0">
                    <label htmlFor="password_confirmation" className="mb-0">Confirme sua nova senha</label>
                    <input
                      type="password"
                      name="password_confirmation"
                      id="password_confirmation"
                      className="form-control"
                      placeholder="Confirme sua senha"
                      onChange={e => setPasswordConfirmation(e.target.value)}
                      disabled={isLoading}
                      required
                    />
                  </div>
                </>
              )}
            </div>

            {!isSuccessful && (
              <div className="card-footer">
                <button type="submit" className="btn btn-primary btn-block d-flex align-items-center justify-content-center" disabled={isLoading}>
                  <span>Redefinir senha</span> {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
                </button>
              </div>
            )}
          </form>
        </div>

        <div className="text-center">
          <h5>Lembrou a sua senha?</h5>
          <Link to="/login">Efetue o login</Link>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
