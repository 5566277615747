import React from 'react'
import '@resources/css/tabs/ask.css'
import IDonor from '@interfaces/IDonor'
import { Accordion } from 'react-bootstrap'

type Props = {
  donor: IDonor
}

const Ask: React.FC<Props> = ({ donor }) => {
  return (
    <section className="donator-about p-3">
      <Accordion>
        {donor.interest_in_becoming_sperm_donor && (
          <Accordion.Item eventKey="0">
            <Accordion.Header><b>O que te despertou o interesse em se tornar um candidato a doador de sêmen?</b></Accordion.Header>
            <Accordion.Body>
              {donor.interest_in_becoming_sperm_donor}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.greatest_quality && (
          <Accordion.Item eventKey="1">
            <Accordion.Header><b>Descreva sua maior qualidade - O que você mais admira em você mesmo?</b></Accordion.Header>
            <Accordion.Body>
              {donor.greatest_quality}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.personality_and_interests && (
          <Accordion.Item eventKey="2">
            <Accordion.Header><b>Como você se descreveria? Conte sobre sua personalidade e interesses</b></Accordion.Header>
            <Accordion.Body>
              {donor.personality_and_interests}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.ambition && (
          <Accordion.Item eventKey="3">
            <Accordion.Header><b>Você tem algum objetivo/sonho/ambição (profissional/pessoal/acadêmico)? Qual?</b></Accordion.Header>
            <Accordion.Body>
              {donor.ambition}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.what_brings_you_happiness && (
          <Accordion.Item eventKey="4">
            <Accordion.Header><b>O que te faz feliz?</b></Accordion.Header>
            <Accordion.Body>
              {donor.what_brings_you_happiness}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.biggest_achievement && (
          <Accordion.Item eventKey="5">
            <Accordion.Header><b>Qual foi sua maior conquista até hoje?</b></Accordion.Header>
            <Accordion.Body>
              {donor.biggest_achievement}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.favorite_childhood_memory && (
          <Accordion.Item eventKey="6">
            <Accordion.Header><b>Qual a sua melhor memória da infância?</b></Accordion.Header>
            <Accordion.Body>
              {donor.favorite_childhood_memory}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {donor.message_to_receivers && (
          <Accordion.Item eventKey="7">
            <Accordion.Header><b>Deixe uma mensagem para a mulher ou casal que realizarão o sonho de construir uma família através da sua doação de sêmen</b></Accordion.Header>
            <Accordion.Body>
              {donor.message_to_receivers}
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </section>

  )
}

export default Ask
