import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'
import axios from 'axios'
import './i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@resources/scss/app.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import '@resources/fontes/fontes.css'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
console.log(axios.defaults.baseURL)
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token') || ''}`

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Router />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
