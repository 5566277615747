import '@resources/fontes/fontes.css'
import React, { useState, useRef } from 'react'
import makeAnimated from 'react-select/animated'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import { Link } from 'react-router-dom'
import CardAbout from '@components/Cards/CardAbout/CardAbout'
import '@resources/css/cardabout/cardabout.css'
import '@resources/css/homeuser.css'
import Team from '@resources/imgs/equipe.png'
import Msg from '@resources/imgs/Mensagem_icon.svg'
import Wpp from '@resources/imgs/wpp.svg'
import Contato from '@resources/imgs/contato.svg'
import Email from '@resources/imgs/email.svg'
import { FaRegCirclePlay } from 'react-icons/fa6'
import YouTube from 'react-youtube'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const animatedComponents = makeAnimated()

const HomeUser: React.FC<any> = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const [mainVideoId, setMainVideoId] = useState('yE_bLl5XVus')
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(mainVideoId)
  const playerRef = useRef<any>(null)

  // Declaração correta de videoIds como uma constante dentro do componente
  const videoIds: string[] = ['oGRA-w9BRds', '6VlOiNxVq0E', '3oDmZGoDtBo']

  const handleThumbnailClick = (videoId: string) => {
    setCurrentVideoId(videoId)
  }

  const handleMainVideoEnd = () => {
    setCurrentVideoId(mainVideoId)
  }

  const onPlayerReady = (event: any) => {
    playerRef.current = event.target
  }

  return (
    <div className="homeuser">
      <Sidebar />
      <main className="with-fixed-nav">
        <section className="homeuser-section">
          <div className="container">
            <div className="about my-5">
              <div className="d-flex justify-content-between my-3">
                <div className="apresentation">
                  <h1>Seja bem vinda <span>Patricia</span></h1>
                  <h4>Você é muito bem vinda a nossa casa!</h4>
                </div>
                <Header />
              </div>
            </div>
            <div className="cards my-5">
              <div className="title">
                <h5>Estamos honrados em sermos a escolha nesta jornada de realização de um sonho tão esperado.</h5>
                <p>Sem dúvidas você é única e não se preocupe nós vamos te ajudar e vai dar certo.</p>
              </div>
              <div className="cardabout">
                <div className="row">
                  <div className="col-12 col-lg-3 my-1">
                    <CardAbout title="Experiência" subtitle="+ de 30 Anos" content="Especialistas confiam e reconhecem"/>
                  </div>
                  <div className="col-12 col-lg-3 my-1">
                    <CardAbout title="DNA Einstein" content="Tudo começou por lá e evoluimos" subtitle="Nossa História"/>
                  </div>
                  <div className="col-12 col-lg-3 my-1">
                    <CardAbout title="One Stop Shop" content="Um único lugar, todas as possibilidades" subtitle="Sim, nós temos tudo"/>
                  </div>
                  <div className="col-12 col-lg-3 my-1">
                    <CardAbout title="Amostras" content="Um banco de dados com mais de 1.000 opções" subtitle="Nacionais e Importadas"/>
                  </div>
                </div>
              </div>
            </div>

            <div className="experience my-5">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                  <div className="left">
                    <h2>Somos o maior e mais completo banco de sêmen do Brasil!</h2>
                    <p>Com mais de 30 anos de experiência construída com DNA Eistein, nos tornamos único banco de sêmen do Brasil que possui amostra nacionais e importadas.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-6">
                  <div className="right">
                    <figure>
                      <img src={Team} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <div className="reserva my-5 p-4 p-md-5">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <h2 className="text-white text-center text-md-left">Então, vamos iniciar sua reserva?</h2>
                </div>
                <div className="col-12 col-lg-5 text-center text-md-right">
                  <button className="btn btn-primary-white">
                    Iniciar
                  </button>
                </div>
              </div>
            </div>

            <div className="contact my-5">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-6">
                  <div className="left">
                    <h2>É claro que estamos sempre a disposição em nossos canais para lhe auxiliar no que for preciso.</h2>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6">
                  <div className="right">
                    <div className="box">
                      <div className="circle">
                        <figure>
                          <img src={Msg} alt="" />
                        </figure>
                      </div>
                      <div className="text">
                        <h6>Webchat</h6>
                        <p>Fale com um consultor</p>
                      </div>
                    </div>
                    <div className="box">
                      <div className="circle">
                        <figure>
                          <img src={Wpp} alt="" />
                        </figure>
                      </div>
                      <div className="text">
                        <h6>WhatApp</h6>
                        <p>(11) 99698-9633</p>
                      </div>
                    </div>
                    <div className="box">
                      <div className="circle">
                        <figure>
                          <img src={Contato} alt="" />
                        </figure>
                      </div>
                      <div className="text">
                        <h6>Telefone</h6>
                        <p>(11) 3171-1196</p>
                      </div>
                    </div>
                    <div className="box">
                      <div className="circle">
                        <figure>
                          <img src={Email} alt="" />
                        </figure>
                      </div>
                      <div className="text">
                        <h6>E-mail</h6>
                        <p>contato@proseed.com.br</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-area my-5">
              <div className="title my-5">
                <h3>Para lhe auxiliar nesta jornada, criamos alguns vídeos:</h3>
                <p>Conheça mais sobre nós e faça sua escolha no seu tempo e conforto do seu lar.</p>
              </div>

              <div className="main-video">
                <div className="video-wrapper embed-responsive embed-responsive-16by9">
                  {currentVideoId && (
                    <iframe
                      key={currentVideoId}
                      title="main-video"
                      width="100%"
                      height="517"
                      src={`https://www.youtube.com/embed/${currentVideoId}`}
                      frameBorder="0"
                      allowFullScreen
                      onLoad={onPlayerReady}
                    ></iframe>
                  )}
                </div>
              </div>

              <Slider {...settings} className="video-carousel">
                {videoIds.map((videoId: string, index: number) => (
                  <div key={index} className="video-thumbnail" onClick={() => handleThumbnailClick(videoId)}>
                    <FaRegCirclePlay className="play-icon" />
                    <img
                      src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
                      alt={`Vídeo ${index + 1}`}
                    />
                    <p>Nome do Vídeo {index + 1}</p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      </main>

    </div>
  )
}

export default HomeUser
