import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Corrida from './../../resources/imgs/Pro-Seed-Corrida-dos-Espermas-.png'
import '@resources/css/clinicselectmodal.css'

const ClinicSelectModal: React.FC<any> = ({ showModal, handleCloseModal }) => {

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Body>
          <figure>
            <img src={Corrida} alt="" className="img-fluid w-50" />
          </figure>
          <h3>Muito bem! Primeiro passo foi dado!</h3>
          <p>O primeiro passo para realizar o seu sonho é encontrar uma clínica de fertilização assistida para fazer seu tratamento. </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/solicitacao" className="btn-obg btn btn-primary">Já encontrei a clínica, vou continuar!</Link>
          <Link to="https://proseed.com.br/encontre-uma-clinica/" target="_blank" className="btn-search btn btn-primary">Quero encontrar uma clínica.</Link>
        </Modal.Footer>
      </Modal>
    </>

  )
}

export default ClinicSelectModal
