import axios from 'axios'

export const all = (searchParams?: URLSearchParams) => axios.get('v1/catalogo/donors', {
  params: searchParams || undefined,
})

export const find = (id: string, options?: any) => {
  return axios.get(`v1/catalogo/donors/${id}`, options)
}

export const characteristics = () => axios.get('v1/catalogo/donors/characteristics')
