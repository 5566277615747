import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

type CompareResultsContextProps = {
  donators: any[]
  setDonators: React.Dispatch<React.SetStateAction<any[]>>
}

const CompareResultsContext = createContext<CompareResultsContextProps>({ } as CompareResultsContextProps)

export const CompareResultsProvider: React.FC<any> = () => {
  const [ donators, setDonators ] = useState<any[]>([])

  return (
    <CompareResultsContext.Provider
      value={{
        donators,
        setDonators
      }}
    >
      <Outlet />
    </CompareResultsContext.Provider>

  )
}

export default CompareResultsContext
