import styled from 'styled-components'

export const Toggle = styled.button`
  background-color: transparent;
  padding-top: 0;
  border: none;
`

export const Button = styled.button`
  color: grey;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Username = styled.div`
  color: #202020;
  text-align: start;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
`