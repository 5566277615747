import React, { useEffect, useState } from 'react'
import LogoIcon from '@resources/imgs/logo-icon.png'
import { SpinnerContainer, SpinnerIcon, SpinnerText } from './LoadingSpinner.styles'

interface LoadingSpinnerProps {
  isLoading: boolean;
  text?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ isLoading, text }) => {
  const [visible, setVisible] = useState(isLoading)

  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setVisible(false), 500)
      return () => clearTimeout(timeout)
    } else {
      setVisible(true)
    }
  }, [isLoading])

  if (!visible) {
    return null
  }

  return (
    visible && (
      <SpinnerContainer isVisible={isLoading}>
        <SpinnerIcon>
          <img src={LogoIcon} alt="Loading" style={{ width: '100%', height: '100%' }} />
        </SpinnerIcon>
        {text && <SpinnerText>{text}</SpinnerText>}
      </SpinnerContainer>
    )
  )
}

export default LoadingSpinner
