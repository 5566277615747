import '@resources/fontes/fontes.css'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Card } from 'react-bootstrap'
import makeAnimated from 'react-select/animated'
import '@resources/fontes/fontes.css'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import Accordion from 'react-bootstrap/Accordion'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineVisibility } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import Chart from '@resources/imgs/chart.png'
import Tratament1 from '@resources/imgs/tratament-1.png'
import Prancheta from '@resources/imgs/receiptdisscount.svg'
import Photo from '@resources/imgs/desenho-perfil.png'
import Foto from '@resources/imgs/mask-group-2.png'
import iicon from '@resources/imgs/i-icon.svg'
import '@resources/css/order.css'
import { FaCheckCircle } from 'react-icons/fa'
import { IoChevronDown, IoTrash } from 'react-icons/io5'
import Tratamento from '@resources/imgs/esperma-1.svg'
import Hospital from '@resources/imgs/hospital.svg'
import Edit from '@resources/imgs/edit.svg'
import Esperma1 from '@resources/imgs/esperma--1.svg'
import Medico from '@resources/imgs/medico--1.svg'
import Carrinho from '@resources/imgs/carrinho.svg'
import LogoEmpresa from '@resources/imgs/logo-empresa.png'
import Add from '@resources/imgs/useradd.svg'
import LogoProssed from '@resources/imgs/logo-proseed-2.png'
import { IoIosCheckmark, IoIosMove, IoMdTime } from 'react-icons/io'
import '@resources/css/patient.css'
import { Tabs } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Families from './Tabs/Families/Families'
import Pix from './Tabs/Pix/Pix'
import Ted from './Tabs/Ted/Ted'
import Pagar from './Tabs/Pagar/Pagar'
import Uol from './Tabs/Uol/Uol'
import { LuFileUp } from 'react-icons/lu'
import { ChangeEvent } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { BsChevronRight } from 'react-icons/bs'

const animatedComponents = makeAnimated()

const options = [
  { value: 'A7', label: 'A7' }
]

const Order: React.FC<any> = () => {
  const [comprovantePagamentoFile, setComprovantePagamentoFile] = useState<File | null>(null)
  const [transportadoraFile, setTransportadoraFile] = useState<File | null>(null)
  const [paymentMethodTab, setPaymentMethodTab] = useState<string>('Pix')
  const [totalProgress, setTotalProgress] = useState(80)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, fileType: 'comprovantePagamento' | 'transportadora') => {
    const file = event.target.files ? event.target.files[0] : null

    if (fileType === 'comprovantePagamento') {
      setComprovantePagamentoFile(file)
    } else if (fileType === 'transportadora') {
      setTransportadoraFile(file)
    }
  }
  const navigate = useNavigate()

  return (
    <div className="patient-summary">
      <Sidebar />
      <main className="with-fixed-nav">
        <div className="container">
          <div className="row justify-content-between mt-3 mb-3">
            <div className="col-7 col-sm-7 col-md-3 d-none d-md-block">
              <div className="row m-0">
                <div className="col-auto pr-0">
                  <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                    Home
                  </a>
                </div>
                <div className="col-auto pl-1 pr-1">
                  <BsChevronRight size={10} />
                </div>
                <div className="col-auto p-0">
                  <a href="#" className="id d-flex align-items-center font-weight-bold">
                    Solicitação de pedidos
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-7 col-md-3 pl-5">
              <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
            </div>
            <div className="col-5 col-sm-5 col-md-6">
              <Header />
            </div>
          </div>
          <div className="pt-2 pb-2 d-block d-md-none">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Home
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Solicitação de pedidos
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <section className="summary-area">
            <div className="text">
              <h5>Confira como está a sua solicitação</h5>
              <p>Realize o passo a passo para receber a sua amostra.</p>
            </div>

            <div className="summary-order row">
              <div className="order-id col-auto">
                <figure className="mb-0 mr-3 progress-circle">
                  <CircularProgressbar
                    value={totalProgress}
                    text={`${totalProgress}%`}
                    styles={buildStyles({

                      // Colors
                      pathColor: `rgba(0, 149, 128, ${totalProgress / 100})`,
                      textColor: '##202020',
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                  />
                </figure>
                <div className="order-detail d-flex flex-column">
                  <h5>Pedido: PRO-0001</h5>
                  <span>12/05/2023 às 12:30h</span>
                </div>
              </div>
              <div className="patient col-auto">
                <figure className="mb-0 mr-3">
                  <img className="photo" src={Photo} alt="" />
                </figure>
                <div className="order-detail d-flex flex-column">
                  <h6>Patricina Fernandez</h6>
                  <span>Paciente</span>
                </div>
              </div>
              <div className="patient col-auto">
                <figure className="mb-0 mr-3">
                  <img src={Tratament1} alt="" />
                </figure>
                <div className="order-detail d-flex flex-column">
                  <h6>FIV</h6>
                  <span>Tratamento</span>
                </div>
              </div>
              <div className="patient col-auto">
                <figure className="mb-0 mr-3">
                  <img src={Hospital} alt="" />
                </figure>
                <div className="order-detail d-flex flex-column">
                  <h6>Huntington</h6>
                  <span>Clínica</span>
                </div>
              </div>
              <div className="patient col-auto">
                <figure className="mb-0 mr-3">
                  <img src={Prancheta} alt="" />
                </figure>
                <div className="order-detail d-flex flex-column">
                  <h6>R$ 8.635,00</h6>
                  <span>Valor do pedido</span>
                </div>
              </div>
            </div>
          </section>

          <section className="accordion box-card">
            <div className="title">
              <h3>Passos para conclusão da solicitação</h3>
              <span>Em progresso</span>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="mb-0">Qual é a clínica de tratamento?</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Passo finalizado com sucesso!</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="request-summer-page">
                    <div className="check-sample">
                      <div className="check check-clinic">
                        <div className="summary row align-items-stretch">
                          <div className="col-12 col-sm-6 col-md-3 mb-3">
                            <div className="box adress">
                              <div className="clinic d-flex">
                                <img src={Hospital} alt="" />
                                <div className="text">
                                  <h6>Huntington</h6>
                                  <span>Clínica de tratamento</span>
                                </div>
                              </div>
                              <div className="andress">
                                <h6>São Paulo - SP</h6>
                                <span>Localidade</span>
                              </div>
                              <div className="email">
                                <h6>Empresa@empresa.com.br</h6>
                                <span>E-mail</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-3 mb-3">
                            <div className="box medic">
                              <div className="medic d-flex">
                                <img src={Medico} alt="" />
                                <div className="text">
                                  <h6>Dr. Carlos Albuquer</h6>
                                  <span>Médico responsável</span>
                                </div>
                              </div>
                              <div className="email">
                                <h6>Empresa@empresa.com.br</h6>
                                <span>E-mail</span>
                              </div>
                              <div className="crm">
                                <h6>9591233</h6>
                                <span>CRM</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 mb-3">
                            <div className="box tratament">
                              <div className="tratament d-flex">
                                <img src={Esperma1} alt="" />
                                <div className="text">
                                  <h6>Fertilização in vitro (FIV)</h6>
                                  <span>Tipo de tratamento</span>
                                </div>
                              </div>
                              <div className="form-group w-100">
                                <div className="text-box d-flex">
                                  <p className="">Quantidade de amostras</p>
                                  <img src={iicon} alt="" />
                                </div>
                                <Select
                                  className="react-select"
                                  placeholder={<div>Selecione</div>}
                                  components={animatedComponents}
                                  options={options}
                                  isClearable={true}
                                  isSearchable={true}
                                  isDisabled={false}
                                  isLoading={false}
                                  isRtl={false}
                                  closeMenuOnSelect={true}
                                />
                              </div>
                              <div className="data mt-2">
                                <h6>15 de novembro 2024</h6>
                                <span>Data de tratamento</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-2 align-self-center">
                            <div className="edit mt-2">
                              <Link className="d-flex" to="#">
                                <img src={Edit} alt="" />
                                <span>Editar dados</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="check check-patient">
                        <div className="summary row align-items-stretch">
                          <div className="col-12 col-sm-6 col-md-5">
                            <div className="box box-user mt-2">
                              <div className="name">
                                <h6>Patricia Fernandez</h6>
                                <span>Nome da solicitante</span>
                              </div>
                              <div className="cpf">
                                <h6>066.315.879-89</h6>
                                <span>CPF</span>
                              </div>
                              <div className="tell">
                                <h6>(41) 99693-8565</h6>
                                <span>Celular</span>
                              </div>
                              <div className="email">
                                <h6>Empresa@empresa.com.br</h6>
                                <span>E-mail</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-5">
                            <div className="box box-partner mt-2">
                              <div className="name">
                                <h6>João Carlos Gonçalves</h6>
                                <span>Companheiro(a)</span>
                              </div>
                              <div className="cpf">
                                <h6>525.698.336-69</h6>
                                <span>CPF</span>
                              </div>
                              <div className="tell">
                                <h6>(41) 99789-9532</h6>
                                <span>Celular</span>
                              </div>
                              <div className="email">
                                <h6>empresa2@empresa.com.br</h6>
                                <span>E-mail</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 align-self-center">
                            <div className="edit mt-2">
                              <Link className="d-flex" to="/perfil">
                                <img src={Edit} alt="" />
                                <span>Editar dados</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="">Escolha seus doadores</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Passo finalizado com sucesso!</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Card className="donator-card mb-3 w-100">
                          <Card.Header className="card-header-donator d-flex justify-content-between" id="card-swiper">
                            <div className="top-icons d-flex">
                              <div className="check mx-1">
                                <IoIosCheckmark />
                              </div>
                              <div className="priority mx-1">
                                Prioridade
                                <span>01</span>
                              </div>
                              <div className="icons move-icon mx-1">
                                <IoIosMove />
                              </div>
                              <button className="icons trash-icon mx-1">
                                <IoTrash />
                              </button>
                            </div>
                            <div className="price">
                              R$ 2.689,00
                            </div>
                          </Card.Header>

                          <Card.Body className="card-body-donator d-flex align-items-center">
                            <figure className="d-flex justify-content-center ">
                              <img className="photo" src={Foto} alt="" />
                            </figure>
                            <div className="d-flex flex-column justify-content-center p-3">
                              <div id="dados">
                                <div className="id">ID: 13</div>
                                <div className="amostra">Amostra Nacional</div>
                              </div>
                              <div className="tratament-logo d-flex align-items-center justify-content-between">
                                <div className="tratament d-flex align-items-center justify-content-between">
                                  <img src={Tratamento} alt="" />
                                  <span>FIV</span>
                                </div>
                                <figure className="">
                                  <img src={LogoProssed} alt="" />
                                </figure>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Card className="donator-card mb-3 w-100">
                          <Card.Header className="card-header-donator d-flex justify-content-between" id="card-swiper">
                            <div className="top-icons d-flex">
                              <div className="check mx-1">
                                <IoIosCheckmark />
                              </div>
                              <div className="priority mx-1">
                                Prioridade
                                <span>02</span>
                              </div>
                              <div className="icons move-icon mx-1">
                                <IoIosMove />
                              </div>
                              <button className="icons trash-icon mx-1">
                                <IoTrash />
                              </button>
                            </div>
                            <div className="price">
                              R$ 2.689,00
                            </div>
                          </Card.Header>

                          <Card.Body className="card-body-donator d-flex align-items-center">
                            <figure className="d-flex justify-content-center ">
                              <img className="photo" src={Foto} alt="" />
                            </figure>
                            <div className="d-flex flex-column justify-content-center p-3">
                              <div id="dados">
                                <div className="id">ID: 13</div>
                                <div className="amostra">Amostra Nacional</div>
                              </div>
                              <div className="tratament-logo d-flex align-items-center justify-content-between">
                                <div className="tratament d-flex align-items-center justify-content-between">
                                  <img src={Tratamento} alt="" />
                                  <span>FIV</span>
                                </div>
                                <figure className="">
                                  <img src={LogoProssed} alt="" />
                                </figure>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Card className="donator-card mb-3 w-100">
                          <Card.Header className="card-header-donator d-flex justify-content-between" id="card-swiper">
                            <div className="top-icons d-flex">
                              <div className="check mx-1">
                                <IoIosCheckmark />
                              </div>
                              <div className="priority mx-1">
                                Prioridade
                                <span>02</span>
                              </div>
                              <div className="icons move-icon mx-1">
                                <IoIosMove />
                              </div>
                              <button className="icons trash-icon mx-1">
                                <IoTrash />
                              </button>
                            </div>
                            <div className="price">
                              R$ 2.689,00
                            </div>
                          </Card.Header>

                          <Card.Body className="card-body-donator d-flex align-items-center">
                            <figure className="d-flex justify-content-center ">
                              <img className="photo" src={Foto} alt="" />
                            </figure>
                            <div className="d-flex flex-column justify-content-center p-3">
                              <div id="dados">
                                <div className="id">ID: 13</div>
                                <div className="amostra">Amostra Nacional</div>
                              </div>
                              <div className="tratament-logo d-flex align-items-center justify-content-between">
                                <div className="tratament d-flex align-items-center justify-content-between">
                                  <img src={Tratamento} alt="" />
                                  <span>FIV</span>
                                </div>
                                <figure className="">
                                  <img src={LogoProssed} alt="" />
                                </figure>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="mb-0">Solicitação de Reserva</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Passo finalizado com sucesso!</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="summary-order row">
                      <div className="order-id col-auto">
                        <div className="order-detail d-flex flex-column">
                          <h6>Pedido: PRO-0001</h6>
                          <span>12/05/2023 às 12:30h</span>
                        </div>
                      </div>
                      <div className="patient col-auto">
                        <figure className="mb-0 mr-3">
                          <img className="photo" src={Photo} alt="" />
                        </figure>
                        <div className="order-detail d-flex flex-column">
                          <h6>Patricina Fernandez</h6>
                          <span>Paciente</span>
                        </div>
                      </div>
                      <div className="patient col-auto">
                        <figure className="mb-0 mr-3">
                          <img src={Tratament1} alt="" />
                        </figure>
                        <div className="order-detail d-flex flex-column">
                          <h6>FIV</h6>
                          <span>Tratamento</span>
                        </div>
                      </div>
                      <div className="patient col-auto">
                        <figure className="mb-0 mr-3">
                          <img src={Hospital} alt="" />
                        </figure>
                        <div className="order-detail d-flex flex-column">
                          <h6>Huntington</h6>
                          <span>Clínica</span>
                        </div>
                      </div>
                      <div className="patient col-auto">
                        <figure className="mb-0 mr-3">
                          <img src={Prancheta} alt="" />
                        </figure>
                        <div className="order-detail d-flex flex-column">
                          <h6>R$ 8.635,00</h6>
                          <span>Valor do pedido</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="mb-0">Aguardando confirmação de reserva</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Nº Pedido: PRO-0001</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="order-awaiting row m-0 align-items-center justify-content-center">
                      <div className="col-md-8 p-0">
                        <p className="mb-0">Estamos confirmando sua reserva, assim que tivermos uma resposta, atualizaremos o startus.</p>
                      </div>
                      <div className="col-sm-12 col-md-4 d-flex justify-content-md-end justify-content-center mb-3 mt-3">
                        <button className="btn-cancel-order btn btn-primary shadow-none p-2 pl-3 pr-3">
                          Cancelar pedido <FaRegTrashAlt className="ml-4" />

                        </button>
                      </div>
                    </div>
                    <div className="order-confirmed">
                      <p>Parabéns! sua reserva já está confirmada e logo estaremos enviando o  contrato para sua assinatura digital.</p>
                      <figure>
                        <img src={Carrinho} alt="" />
                      </figure>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="mb-0">Assinatura do Contrato</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Passo finalizado com sucesso!</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="awating-logo d-none">
                      <div className="text ml-4">
                        <p>Enviaremos o contrato de fornecimento de sêmen, através da plataforma ClickSign para ser preenchido, lido e assinado.</p>
                        <li>Verifique se o e-mail da ClickSign está na sua caixa de SPAM.</li>
                        <li>A assinatura do contrato deve ser feita ANTES da amostra de sêmen ser enviada para a clínica.</li>
                      </div>
                    </div>
                    <div className="confirmed-logo">
                      <figure>
                        <img src={LogoEmpresa} alt="" />
                      </figure>
                      <div className="text ml-4">
                        <p>Enviaremos o contrato de fornecimento de sêmen, através da plataforma ClickSign para ser preenchido, lido e assinado.</p>
                        <li>Verifique se o e-mail da ClickSign está na sua caixa de SPAM.</li>
                        <li>A assinatura do contrato deve ser feita ANTES da amostra de sêmen ser enviada para a clínica.</li>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="mb-0">Escolha a forma de pagamento da amostra</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Passo finalizado com sucesso!</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="select-payment">
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="box details p-3">
                            <div className="title">
                              <h5>Detalhes do pagamento:</h5>
                              <button className="visibility">
                                <MdOutlineVisibility />
                                Visualizar Pedido
                              </button>
                            </div>
                            <div className="payment-summary">
                              <table>
                                <thead>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>Amostras</th>
                                    <td>03</td>
                                    <td>R$ 8.362,32</td>
                                  </tr>
                                  <tr>
                                    <th>Serviços Extras</th>
                                    <td>02</td>
                                    <td>R$ 362,32</td>
                                  </tr>
                                  <tr>
                                    <th>Descontos</th>
                                    <td>-</td>
                                    <td className="cupom">R$ -200,00</td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th>Total para pagar</th>
                                    <td></td>
                                    <td>R$ 8.362,32</td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="box NF p-3">
                            <div className="title">
                              <h5>Emissão de NFe</h5>
                              <p>Gostaria e emitir a nota fiscal em nome de quem?</p>
                            </div>
                            <div className="content d-flex justify-content-around">
                              <div className="line-content">
                                <div className="title mb-2">
                                  Paciente:
                                </div>
                                <div className="checkbox d-flex align-items-center">
                                  <input className="input-select checkbox-round mr-3" name="input-radio" type="radio" />
                                  <div className="patient">
                                    <div className="text name">
                                      Patricia Fernandez
                                    </div>
                                    <div className="text cpf">
                                      066.315.879-89
                                    </div>
                                    <div className="text tel">
                                      (41) 99693-8565
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="line-content">
                                <div className="title mb-2">
                                  Companheiro:
                                </div>
                                <div className="checkbox d-flex align-items-center">
                                  <input className="input-select checkbox-round mr-3" name="input-radio" type="radio" />
                                  <div className="patient">
                                    <div className="text name">
                                      João Carlos Gonçalves
                                    </div>
                                    <div className="text cpf">
                                      525.698.336-69
                                    </div>
                                    <div className="text tel">
                                      (41) 99789-95323
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-12">
                          <div className="box payment p-3">
                            <div className="title">
                              <div className="text">
                                <h5>Formas de pagamento</h5>
                                <p>Escolha uma forma de pagamento para que possa seguir</p>
                              </div>
                              <div className="button">
                                <div className="btn btn-primary">
                                  Salvar forma de pagamento
                                  <MdKeyboardArrowRight />
                                </div>
                              </div>
                            </div>
                            <div className="line-content px-3">
                              <Tab.Container id="Banks-tabs" defaultActiveKey={paymentMethodTab} onSelect={(tab: any) => setPaymentMethodTab(tab as string)}>
                                <Row>
                                  <Col sm={10}>
                                    <Nav variant="pills" className="d-block d-md-flex justify-content-between">
                                      <Nav.Item>
                                        <Nav.Link eventKey="Pix"> <input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Pix'} type="radio" readOnly /> Pix</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="Ted"><input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Ted'} type="radio" readOnly /> Ted</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="Families"> <input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Families'} type="radio" readOnly /> Families</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="Pagar"><input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Pagar'} type="radio" readOnly /> Pargar.me</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="Uol"><input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Uol'} type="radio" readOnly /> PagSeguro</Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </Col>
                                  <Col>
                                    <Tab.Content>
                                      <Tab.Pane eventKey="Pix"><Pix /></Tab.Pane>
                                      <Tab.Pane eventKey="Ted"><Ted /></Tab.Pane>
                                      <Tab.Pane eventKey="Families"><Families /></Tab.Pane>
                                      <Tab.Pane eventKey="Pagar"><Pagar /></Tab.Pane>
                                      <Tab.Pane eventKey="Uol"><Uol /></Tab.Pane>
                                    </Tab.Content>
                                  </Col>
                                </Row>
                              </Tab.Container>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <FaCheckCircle />
                        <h5 className="mb-0">Escolha a forma de pagamento da transportadora</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p>Passo finalizado com sucesso!</p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line green-line"></div>
                        <span>100%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="select-payment">
                      <div className="row">
                        <div className="col-12">
                          <div className="box box-frete">
                            <div className="title d-flex justify-content-between">
                              <h5>Total a pagar pelo frete:</h5>
                              <h5>RS 300,00</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="box payment">
                            <div className="title p-3">
                              <div className="text">
                                <h5>Formas de pagamento</h5>
                                <p>Escolha uma forma de pagamento para que possa seguir</p>
                              </div>
                              <div className="button">
                                <div className="btn btn-primary">
                                  Salvar forma de pagamento
                                  <MdKeyboardArrowRight />
                                </div>
                              </div>
                            </div>
                            <div className="line-content px-3">
                              <Tab.Container id="Banks-tabs" defaultActiveKey={paymentMethodTab} onSelect={(tab: any) => setPaymentMethodTab(tab as string)}>
                                <Row>
                                  <Col sm={10}>
                                    <Nav variant="pills" className="d-flex">
                                      <Nav.Item className="mr-5">
                                        <Nav.Link eventKey="Pix"> <input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Pix'} type="radio" readOnly /> Pix</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="Ted"><input style={{ pointerEvents: 'none' }} className="input-select checkbox-round mr-3" checked={paymentMethodTab === 'Ted'} type="radio" readOnly /> Ted</Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </Col>
                                  <Col>
                                    <Tab.Content>
                                      <Tab.Pane eventKey="Pix"><Pix /></Tab.Pane>
                                      <Tab.Pane eventKey="Ted"><Ted /></Tab.Pane>
                                    </Tab.Content>
                                  </Col>
                                </Row>
                              </Tab.Container>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7" className="">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="desabled-circle d-none">
                      </div>
                      <div className="check-area">
                        <div className="check-icon d-none">
                          <FaCheckCircle />
                        </div>
                        <div className="time-icon">
                          <IoMdTime />
                        </div>
                        <h5 className="mb-0">Envio do comprovante pagamento</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1">
                      <p></p>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line awating-line"></div>
                        <span>50%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <div className="select-payment">
                      <div className="box payment-file">
                        <div className="title">
                          <h5>Enviar comprovante de pagamento</h5>
                          <p>Anexe o comprovante de pagamento aqui para seguir com o próximo passo.</p>
                        </div>
                        <div className="file-select">
                          <div className="left mx-3">
                            <h6>Primeiro precisa inserir o comprovante de pagamento</h6>
                            {comprovantePagamentoFile ? (
                              <div className="file-info">
                                <div className="circle">
                                  <div className="circle-100">100%</div>
                                </div>
                                <div className="d-flex">
                                  <p>{comprovantePagamentoFile.name}</p>
                                  <button className="remove-button icons trash-icon" onClick={() => setComprovantePagamentoFile(null)}>
                                    <IoTrash />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <label htmlFor="comprovantePagamentoInput" className="file-input-label btn btn-primary d-flex justify-content-center align-items-center flex-column">
                                <LuFileUp />
                                <p>Clique aqui e faça o upload</p>
                                <input
                                  id="comprovantePagamentoInput"
                                  type="file"
                                  accept=".pdf, .doc, .docx"
                                  style={{ display: 'none' }}
                                  onChange={(e) => handleFileChange(e, 'comprovantePagamento')}
                                />
                              </label>
                            )}
                          </div>
                          <div className="right mx-3">
                            <h6>Agora insira o comprovante da transportadora</h6>
                            {transportadoraFile ? (
                              <div className="file-info">
                                <div className="circle">
                                  <div className="circle-100">100%</div>
                                </div>
                                <div className="d-flex">
                                  <p>{transportadoraFile.name}</p>
                                  <button className="remove-button icons trash-icon" onClick={() => setTransportadoraFile(null)}>
                                    <IoTrash />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <label htmlFor="transportadoraInput" className="file-input-label btn btn-primary d-flex justify-content-center align-items-center flex-column">
                                <LuFileUp />
                                <p>Clique aqui e faça o upload</p>
                                <input
                                  id="transportadoraInput"
                                  type="file"
                                  accept=".pdf, .doc, .docx"
                                  style={{ display: 'none' }}
                                  onChange={(e) => handleFileChange(e, 'transportadora')}
                                />
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="buttons">
                          <button className="btn-return btn btn-primary">
                            <MdKeyboardArrowLeft />
                            Retornar para formas de pagamento
                          </button>
                          <button className="btn-save btn btn-primary">
                            Salvar comprovante
                            <MdKeyboardArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8" className=" desabled">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5 desabled-text">
                      <div className="check-area">
                        <div className="time-icon">
                          <IoMdTime />
                        </div>
                        <h5 className="mb-0">Aguardando solicitação da clínica</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1"></div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line"></div>
                        <span>0%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="check-sample p-3">
                    <p className="mb-0">Estamos aguardando o retorno da clínica, assim que tivermos entraremos em contato.</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9" className=" desabled">
                <Accordion.Header>
                  <div className="row w-100 align-items-center">
                    <div className="col-12 col-md-5 desabled-text">
                      <div className="check-area">
                        <div className="time-icon">
                          <IoMdTime />
                        </div>
                        <h5 className="mb-0">Enviado para clínica</h5>
                      </div>
                    </div>
                    <div className="col-md-3 text-md-right text-sm-left mb-1 mt-1"></div>
                    <div className="col-sm-12 col-md-4">
                      <div className="line-color d-flex">
                        <div className="desabled-line"></div>
                        <span>0%</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </section>
        </div>
      </main>
    </div>
  )
}

export default Order
