import React from 'react'
import { Card } from 'react-bootstrap'
import Photo from '@resources/imgs/mask-group-2.png'
import LogoProssed from '@resources/imgs/logo-proseed-2.png'
import '@resources/css/cardsugestion/cardsugestion.css'
import IDonorSuggestion from '@interfaces/IDonorSuggestion'
import LogoEuropean from '@resources/imgs/european.webp'
import LogoSeattle from '@resources/imgs/seattle.png'
import { useNavigate } from 'react-router-dom'

type Props = {
  donor?: IDonorSuggestion
}

const CardSugestion: React.FC<Props> = ({ donor }) => {

  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/doadores/${donor?.internal_id?.toLowerCase()}`)
    window.scrollTo(0, 0)
  }

  return (
    <div className="cardsugestion py-3">
      <Card id="cardsugestion" onClick={handleNavigate}>
        <Card.Header className="card-header-sugestion">
          <figure>
            <img className="photo" src={donor?.avatar || Photo} alt="" />
          </figure>
          <div id="dados" className="text-center">
            <h5 className="id">ID: {donor?.internal_id}</h5>
            <div className="profissao">{donor?.occupation?.length > 17 ? donor?.occupation.substring(0, 17) + '...' : donor?.occupation}</div>
            <div className="idade">{donor?.age} anos</div>
          </div>
        </Card.Header>
        <Card.Body className="card-body-sugestion">
          <div className="amostra-logo">
            <figure className="m-0">
              {donor?.sperm_bank?.name === 'Pro-Seed' ? (
                <img src={LogoProssed} alt="" width={200} />
              ) : donor?.sperm_bank?.name === 'European Sperm Bank' ? (
                <img src={LogoEuropean} alt="" width={200} />
              ) : donor?.sperm_bank?.name === 'Seattle Sperm Bank' ? (
                <img src={LogoSeattle} alt="" width={100} />
              ) : (
                <img src={LogoProssed} alt="" width={200} />
              )}
            </figure>
            <div>
              <h6 className="text-dentro m-0 mb-2">
                <small>{donor?.sperm_bank?.name === 'Pro-Seed' ? 'Amostra Nacional' : 'Amostra Internacional'}</small>
              </h6>
              <h6 className="text-dentro m-0">
                {donor?.sperm_bank?.name === 'Pro-Seed' ? (
                  <small>{donor?.occupation}</small>
                ) : (
                  <small>{donor?.nickname ?? ''}</small>
                )}
              </h6>
            </div>
          </div>
          <div className="info-body">
            <div className="row text-center">
              <div className="col-12 col-lg-6 pt-2">
                <div className="title">
                  Tratamento
                </div>
                <div className="result">
                  <b>{donor?.treatment_names.join(' / ')}</b>
                </div>
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <div className="title">
                  Cabelo
                </div>
                <div className="result">
                  <b>{donor?.hair_color.name}</b>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-12 col-lg-6 pt-2">
                <div className="title">
                  Cor da pele
                </div>
                <div className="result">
                  <b>{donor?.skin_color.name}</b>
                </div>
              </div>
              <div className="col-12 col-lg-6 pt-2">
                <div className="title">
                  Cor dos olhos
                </div>
                <div className="result">
                  <b>{donor?.eye_color.name}</b>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default CardSugestion
