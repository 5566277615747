import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

const options = [
  { value: 'A7', label: 'A7' }
]
const Sample: React.FC<any> = () => {
  return (
    <div className="request-page mt-5">
      <div className="page-title">
        <div className="container">
          <h3>Solicitação da Amostra</h3>
        </div>
      </div>

      <div className="progress-section mb-3">
        <div className="container">
          <div className="progress">
            <div className="progress-line">
              <span className="progress-number">1</span>
              <h5 className="mb-0">Selecionar Doador</h5>
            </div>
            <div className="progress-line">
              <span className="progress-number">2</span>
              <h5 className="mb-0">Amostra</h5>
            </div>
            <div className="progress-line">
              <span className="progress-number">3</span>
              <h5>Check Out</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="select-sample mb-5">
        <div className="container">
          <div className="text-area mb-5">
            <h3>Pedido: 01</h3>
          </div>
          <div className="row">

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex align-items-center justify-content-between w-100">
                  <h5 className="mb-0">Nome:</h5>
                  <Select
                    className="react-select w-50"
                    placeholder={<div>Priscila</div>}
                    components={animatedComponents}
                    options={options}
                    isClearable={true}
                    isSearchable={false}
                    isDisabled={true}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex align-items-center justify-content-between w-100">
                  <h5 className="mb-0">Companheiro:</h5>
                  <Select
                    className="react-select w-50"
                    placeholder={<div>Fernando</div>}
                    components={animatedComponents}
                    options={options}
                    isClearable={true}
                    isSearchable={false}
                    isDisabled={true}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex align-items-center justify-content-between w-100">
                  <h5 className="mb-0">Tratamento:</h5>
                  <Select
                    className="react-select w-50"
                    placeholder={<div>Selecione</div>}
                    components={animatedComponents}
                    options={options}
                    isClearable={true}
                    isSearchable={false}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex align-items-center justify-content-between w-100">
                  <h5 className="mb-0">Clínica:</h5>
                  <Select
                    className="react-select w-50"
                    placeholder={<div>Selecione</div>}
                    components={animatedComponents}
                    options={options}
                    isClearable={true}
                    isSearchable={false}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="card-body section-lines">
        <div className="container">
          <div className="table-responsive">
            <table className="table table-striped table-md">
              <tbody>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Doador</th>
                  <th>Quantidade</th>
                  <th>Amostra</th>
                  <th>Banco</th>
                  <th>Tratamento</th>
                  <th>(R$) Amostra</th>
                </tr>
                <tr>
                  <td>
                    <button className="btn bg-transparent">
                      <AiOutlineMenu />
                    </button>
                  </td>
                  <td>1º</td>
                  <td><Link to="/">322</Link></td>
                  <td>
                    <Select
                      className="react-select w-100"
                      placeholder={<div>1</div>}
                      components={animatedComponents}
                      options={options}
                      isClearable={true}
                      isSearchable={false}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      closeMenuOnSelect={true}
                    />
                  </td>
                  <td>Iternacional</td>
                  <td>Seatle Sperm Bank</td>
                  <td>FIV</td>
                  <td>R$ 6.600,00</td>
                </tr>
                <tr>
                  <td>
                    <button className="btn bg-transparent">
                      <AiOutlineMenu />
                    </button>
                  </td>
                  <td>1º</td>
                  <td><Link to="/">322</Link></td>
                  <td>
                    <Select
                      className="react-select w-100"
                      placeholder={<div>1</div>}
                      components={animatedComponents}
                      options={options}
                      isClearable={true}
                      isSearchable={false}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      closeMenuOnSelect={true}
                    />
                  </td>
                  <td>Iternacional</td>
                  <td>Seatle Sperm Bank</td>
                  <td>FIV</td>
                  <td>R$ 6.600,00</td>
                </tr>
                <tr>
                  <td>
                    <button className="btn bg-transparent">
                      <AiOutlineMenu />
                    </button>
                  </td>
                  <td>1º</td>
                  <td><Link to="/">322</Link></td>
                  <td>
                    <Select
                      className="react-select w-100"
                      placeholder={<div>1</div>}
                      components={animatedComponents}
                      options={options}
                      isClearable={true}
                      isSearchable={false}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      closeMenuOnSelect={true}
                    />
                  </td>
                  <td>Iternacional</td>
                  <td>Seatle Sperm Bank</td>
                  <td>FIV</td>
                  <td>R$ 6.600,00</td>
                </tr>
                <tr>
                  <td>
                    <button className="btn bg-transparent">
                      <AiOutlineMenu />
                    </button>
                  </td>
                  <td>1º</td>
                  <td><Link to="/">322</Link></td>
                  <td>
                    <Select
                      className="react-select w-100"
                      placeholder={<div>1</div>}
                      components={animatedComponents}
                      options={options}
                      isClearable={true}
                      isSearchable={false}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      closeMenuOnSelect={true}
                    />
                  </td>
                  <td>Iternacional</td>
                  <td>Seatle Sperm Bank</td>
                  <td>FIV</td>
                  <td>R$ 6.600,00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="additional-page">
        <div className="container">

          <div className="text-area mb-5">
            <h3>Contratar serviços adicionais</h3>
          </div>

          <div className="d-flex justify-content-between mb-3  px-5">
            <div className="d-flex">
              <input className="input-select" type="checkbox" />
              <p className="mb-0">Photo Match</p>
            </div>
            <div className="right">
              R$ 1.500,00
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3  px-5">
            <div className="d-flex">
              <input className="input-select" type="checkbox" />
              <p className="mb-0">Deixar uma das amostras congeladas no Pro-Seed?</p>
            </div>
            <div className="right">
              R$ 600,00 / mês
            </div>
          </div>

          <div className="mb-3">
            <div className="d-flex px-5">
              <input className="input-select" type="checkbox" />
              <p className="mb-0">Reservar uma segunda amostra para futura gestação?</p>
            </div>
          </div>

        </div>
      </div>

      <div className="transport-page">
        <div className="container">

          <div className="text-area mb-5">
            <h3>Transporte</h3>
          </div>

          <div className="d-flex justify-content-between mb-3 px-5">
            <div>
              <p className="mb-0">Custo adicional de transporte</p>
            </div>
            <div className="right">
              R$ 300,00
            </div>
          </div>

        </div>
      </div>

      <div className="discount-page mb-5">
        <div className="container">
          <div className="text-area mb-5">
            <h3>Cupom de Desconto</h3>
          </div>
          <div className="row">

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex justify-content-between">
                  <h5 className="mb-0">Informe o código de desconto:</h5>
                  <input className="input-select" type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-6">
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex justify-content-between">
                  <h5 className="mb-0">Projeto Social:</h5>
                  <input className="input-select" type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <div className="inputs d-flex justify-content-between px-5">
                  <h5 className="mb-0">Valor do Desconto:</h5>
                  <p className="mb-0">R$ 300,00</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="info-section mb-5">
        <div className="container">

          <div className="title">
            <h3 className="mb-3">Prévia de Valores</h3>
          </div>

          <div className="text-area">
            <p>
              Apenas uma das suas amostras serão reservadas, de acordo com a sequência de sua escolha.
              Nós reservamos ao direito de aplicar alterações de valores sem prévio avisol.
            </p>
          </div>

          <div className="overview">
            <div className="row">
              <div className="col-md-3">
                <div className="table">
                  <tbody>
                    <tr>Banco de Sêmen</tr>
                    <tr>Seatle Sperm Bank</tr>
                  </tbody>
                </div>
              </div>
              <div className="col-md-3">
                <div className="table">
                  <tbody>
                    <tr>Tipo de Amostra</tr>
                    <tr>FIV</tr>
                  </tbody>
                </div>
              </div>
              <div className="col-md-3">
                <div className="table">
                  <tbody>
                    <tr>Tipo de Doador</tr>
                    <tr>ID Aberto</tr>
                  </tbody>
                </div>
              </div>
              <div className="col-md-3">
                <div className="table">
                  <tbody>
                    <tr>(R$) Valor</tr>
                    <tr>R$ 6.600,00</tr>
                  </tbody>
                </div>
              </div>
              <div className="col-md-8">
                <div className="table">
                  <tbody>
                    <tr>Serviço</tr>
                    <tr>Photo Match</tr>
                    <tr>Criopreservação da amostra extra</tr>
                  </tbody>
                </div>
              </div>
              <div className="col-md-4">
                <div className="table">
                  <tbody>
                    <tr>Valor</tr>
                    <tr>R$ 1.500,00</tr>
                    <tr>R$ 600,00</tr>
                  </tbody>
                </div>
              </div>
            </div>
          </div>

          <div className="preview">
            <div className="container">
              <div className="text-area">
                <h3 className="mb-3">- Cupom de desconto: R$ 300,00</h3>
                <h3>- Prévia total a pagar: R$ 8.500,00</h3>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="buttons-section">
        <div className="container">

          <div className="buttons d-flex justify-content-between">
            <button className="btn btn-primary mb-5">Voltar</button>
            <button className="btn btn-primary mb-5">Check-Out</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Sample
