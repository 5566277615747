import '@resources/fontes/fontes.css'
import React from 'react'
import QR from '@resources/imgs/qr.png'
import Santander from '@resources/imgs/bank.png'

const Pix: React.FC<any> = () => {

  return (
    <>
      <div className="line-content pix">
        <div className="check-sample">
          <div className="left d-flex">
            <div className="code mr-md-5 mr-0">
              <figure>
                <img className="img-fluid" src={QR} alt="" />
              </figure>
              <button>
                Copiar chave
              </button>
            </div>
            <div className="bank">
              <div className="title">
                <figure>
                  <img className="img-fluid" src={Santander} alt="" />
                </figure>
                Santander
              </div>
              <div className="text">
                <p>AG: 53698521-12</p>
                <p>C/c: 6165468412-3</p>
                <p>CNPJ: 22.312.316/0001-22</p>
              </div>
            </div>
          </div>
          <div className="right">
            <span>Assim que salvar,</span> já poderá realizar o upload do comprovante.
          </div>
        </div>
      </div>
    </>

  )
}

export default Pix
